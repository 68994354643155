<script>
export default {
  props: {
    photo: { type: String },
    initials: { type: String },
    large: { type: Boolean, default: false },
    enableZoom: { type: Boolean, default: false },
  },
  data() {
    return { isZoomed: false }
  },
  computed: {
    zoomClass() {
      return {
        'avatar--zoom-enabled': this.enableZoom,
        'avatar--zoomed': this.isZoomed,
      }
    },
    sizeClass() {
      return this.large ? 'avatar--large' : ''
    },
  },
  methods: {
    handleZoom(e) {
      if (this.enableZoom && !this.isZoomed) {
        e.preventDefault()
        this.isZoomed = true
      }
    },
  },
}
</script>

<template>
  <div class="avatar" :class="[sizeClass, zoomClass]" @mouseleave="isZoomed = false">
    <template v-if="photo">
      <img :src="photo" class="avatar__photo" :alt="initials" @click="handleZoom" />
      <img v-if="enableZoom" src="@/assets/icons/eye-white.svg" class="avatar__eye" />
    </template>
    <span v-else class="initials">{{ initials }}</span>
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  position: relative;
  display: grid;
  place-items: center;
  background-color: var(--color-grey-med);
  font-weight: 500;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  overflow: hidden;

  &--zoom-enabled {
    transition: transform 0.2s;

    .avatar__photo {
      filter: blur(0);
      transition: filter 0.6s cubic-bezier(0, 0.55, 0.45, 1);
    }

    &:hover {
      cursor: pointer;

      .avatar__photo {
        filter: blur(2px);
      }

      .avatar__eye {
        opacity: 1;
      }
    }
  }

  &--zoomed {
    transform: scale(2.15);
    cursor: auto;

    .avatar__photo {
      filter: blur(0) !important;
    }

    .avatar__eye {
      opacity: 0 !important;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
  }

  &--large {
    width: 120px;
    height: 120px;
    border-radius: 16px;
    font-size: 24px;

    .avatar__photo {
      width: 120px;
      height: 120px;
      border-radius: 16px;
    }
  }

  &__eye {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.3s;
  }

  .initials {
    text-transform: uppercase;
  }
}
</style>
