<script lang="ts">
import { defineComponent } from 'vue'
import type { IconPropType } from '@keyo/ui'
import { Icon, TooltipWrapper } from '@keyo/ui'
import useSidebar from './useSidebar'

export default defineComponent({
  name: 'MenuLink',

  components: {
    TooltipWrapper,
    Icon,
  },
  props: {
    icon: String as IconPropType,
    label: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },
  setup() {
    const { isSideBarCollapsed } = useSidebar()
    return { isSideBarCollapsed }
  },
  computed: {
    menuLinkClass() {
      return {
        'menu-link--collapsed': this.isSideBarCollapsed,
      }
    },
    isExternal() {
      return (this.$attrs?.target as string) === '_blank'
    },
  },
})
</script>

<template>
  <TooltipWrapper
    :tag
    :text="label"
    position="right"
    hide-arrow
    class="menu-link"
    :class="menuLinkClass"
    :enabled="isSideBarCollapsed"
  >
    <Icon v-if="icon" :name="icon" class="icon" />
    <span class="text-label-s">{{ label }}</span>
    <Icon v-if="isExternal" name="right-up-filled" class="icon icon--external" />
  </TooltipWrapper>
</template>

<style lang="scss" scoped>
.menu-link {
  display: flex;
  position: relative;
  height: 2.5rem;
  gap: 0.5rem;
  padding: 0 0.75rem;
  margin-right: 0.25rem;
  color: var(--clr-txt-subtle);
  border-radius: 0.75rem;
  align-items: center;
  transition: 0.2s;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    color: var(--clr-icon-subtle);
  }
  .icon--external {
    margin-left: auto;
    color: var(--clr-icon-subdued);
  }

  &:hover {
    background: var(--clr-bg-secondary);
  }
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--clr-border-focus);
  }
  &.router-link-active {
    background: var(--clr-bg-tertiary);
    span {
      font-weight: 600;
    }
  }

  :deep(.tooltip) {
    display: none;
  }
}
@media screen and (min-width: $mobile) {
  .menu-link--collapsed {
    padding: 0;
    margin-right: 0;
    justify-content: center;
    aspect-ratio: 1;

    span,
    .icon--external {
      display: none;
    }

    :deep(.tooltip) {
      display: block;
    }
  }
}
</style>
