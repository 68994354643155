import '@/api' // initiate axios settings
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './pages/router.ts'
import { SvgGradientsPlugin } from '@keyo/ui'

import { i18n, getPreferredLocale } from '@keyo/core/i18n'
import * as messages from '@/locales'
import { SELECTED_LOCALE } from '@/constants/localStorageKeys'
import { coreConfig, CaptchaPlugin } from '@keyo/core'
import { H_CAPTCHA_SITE_KEY } from '@/constants/config.ts'
import { updateApiHeaders } from '@/api'

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// Initialize messages for dashboard app
i18n.global.mergeLocaleMessage('en', messages.en)
i18n.global.mergeLocaleMessage('es', messages.es)
i18n.global.mergeLocaleMessage('fr', messages.fr)

const locale = getPreferredLocale(localStorage.getItem(SELECTED_LOCALE))?.locale
if (locale) {
  i18n.global.locale.value = locale
  updateApiHeaders({ 'Accept-Language': locale })
}

coreConfig.initialize({
  API_URL: import.meta.env.APP_BASE_API_URL,
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://c37bf097e38641af1a0d3de96db9aa18@o4505118487216128.ingest.us.sentry.io/4506822114344960',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/dashboard(\.(rookie|qa))?\.keyo\.co\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
})

app
  .use(pinia)
  .use(router)
  .use(SvgGradientsPlugin)
  .use(i18n)
  .use(CaptchaPlugin, {
    siteKey: H_CAPTCHA_SITE_KEY,
  })
  .mount('#app')
