<script setup lang="ts">
import { Anchor } from '@keyo/ui'
import { usePersonalStore } from '@/modules/account'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
const personalStore = usePersonalStore()
const { profile } = storeToRefs(personalStore)

const props = defineProps<{ show?: boolean }>()
const canShow = computed(() => props.show || (profile.value.phone && profile.value.email))
</script>

<template>
  <Anchor v-if="canShow" class="justify-center" type="button" tag="button">
    {{ $t('modules.auth.common.didNotGetCode') }}
  </Anchor>
</template>
