export const states = ['error', 'success', '']
export const inputAttrsNames = [
  'autocomplete',
  'max',
  'maxlength',
  'min',
  'minlength',
  'name',
  'readonly',
  'required',
  'type',
  'pattern',
  'autocapitalize',
  'inputmode',
]
