export const KEYO_WEB = import.meta.env.APP_KEYO_WEB_URL

export const TERMS_OF_USE = import.meta.env.APP_TERMS_OF_USE
export const PRIVACY_POLICY = import.meta.env.APP_PRIVACY_POLICY
export const BIOMETRIC_POLICY = import.meta.env.APP_BIOMETRIC_POLICY

export const POSTMAN = 'https://postman.keyo.co/'

export const API_REFERENCE = import.meta.env.APP_API_REF

export const ARTICLE_PRIVACY_BY_DESIGN = 'https://medium.com/@tam_keyo/66d3953de7f1'
export const ARTICLE_WHY_KEYO =
  'https://medium.com/onlykeyo/why-youll-love-being-a-keyo-member-9eb9a25e3139'
export const BUSINESSES = 'https://keyo.com/businesses'

export const CONTACT_US = 'https://keyo.com/contact-us'
export const FEATURE_REQUEST = 'https://keyo.canny.io/feature-requests'
export const FEEDBACK_FORM =
  'https://support.keyo.com/hc/en-us/requests/new?ticket_form_id=16679879395085'

export const DEV_PORTAL = 'https://developers.keyo.co'
export const DEV_PORTAL_INTEGRATION = `${DEV_PORTAL}/webhooks/integrating-keyo-client-or-server-apps-into-your-application`
export const DEV_PORTAL_PUBLIC_API_GUIDE = `${DEV_PORTAL}/api-docs/how-to-interact-with-organization-and-user-data-via-keyo-api`

export const KNOWLEDGE_HUB = 'https://www.keyo.com/knowledge'
export const KNOWLEDGE_HUB_GET_STARTED = `${KNOWLEDGE_HUB}/getting-started`
