<script setup lang="ts">
import DrawerManager from '@/libs/drawer/DrawerManager.vue'

import useSidebar from './useSidebar'
import DashboardHeader from './DashboardHeader.vue'
import Sidebar from './Sidebar.vue'
import { reactive } from 'vue'

const { isSideBarCollapsed } = useSidebar()

const drawerOffsetStyles = reactive({
  paddingRight: '0',
})
function handleDrawerUpdate({ offsetWidth }: { offsetWidth: number }) {
  drawerOffsetStyles.paddingRight = `${offsetWidth}px`
}
</script>

<template>
  <div
    class="dashboard__layout"
    :class="{ 'is-sidebar-collapsed': isSideBarCollapsed }"
    :style="drawerOffsetStyles"
  >
    <div class="dashboard__shell">
      <Sidebar />
      <DashboardHeader />
    </div>
    <DrawerManager @update="handleDrawerUpdate" />
    <main>
      <slot />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.dashboard__layout {
  border-top: var(--header-height) solid transparent; // using border instead of margin or padding to have correct height and correct boundaries within `border-box`
  height: 100%;
  display: grid; // allows to center content vertically
  overflow: auto;
}
.dashboard__shell {
  z-index: var(--z-shell);
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: var(--modal-scroll-width-adjust); // modal scroll jumping fix
  display: flex;

  :deep(> *) {
    pointer-events: auto;
  }
}
main {
  // allows to center content vertically if needed
  display: grid;
  align-items: start;
  // 6rem additional space for chat widget
  padding: 1.5rem 1rem 6rem;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.drawer-manager {
  z-index: var(--z-drawer);
}

@media screen and (min-width: $mobile) {
  .dashboard__layout {
    margin-left: var(--sidebar-width);
    transition:
      margin-left var(--sidebar-transition),
      padding-right 0.25s ease-in-out;

    &.is-sidebar-collapsed {
      margin-left: var(--sidebar-width-collapsed);
    }
  }

  main {
    padding: 1rem 1.5rem 0.75rem 2.25rem;
    max-width: var(--max-page-width);
    margin: 0 auto;
    width: 100%;
  }
}
</style>
