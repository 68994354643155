export const routes = [
  {
    path: '/guides/camera-permission',
    name: 'guides.camera-permission',
    component: () => import('./pages/PermissionGuides.vue'),
    redirect: { name: 'guides.camera-permission.ios' },
    meta: {
      isOpen: true,
    },
    children: [
      {
        path: 'ios',
        name: 'guides.camera-permission.ios',
        component: () => import('./pages/Apple.vue'),
      },
      {
        path: 'android',
        name: 'guides.camera-permission.android',
        component: () => import('./pages/Android.vue'),
      },
    ],
  },
]
