export default [
  {
    path: '/organizations/:id/members',
    meta: {
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '',
        name: 'org.members',
        component: () => import('@/pages/organization/users/MemberList.vue'),
      },
      {
        path: '/organizations/:id/members/:memberId',
        component: () => import('@/pages/organization/users/Member.vue'),
        children: [
          {
            path: '',
            name: 'org.member',
            component: () => import('@/pages/organization/users/MemberGeneral.vue'),
          },
        ],
      },
    ],
  },
]
