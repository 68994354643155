<script setup lang="ts">
import { inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import { DialogBtn, PasswordStrength, Pin, TextField } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import type { AxiosError } from 'axios'

import { usePersonalStore } from '../pinia'

import useModal from '@/composables/useModal'
import useFormHelpers from '@/composables/useFormHelpers'
import { mfaCode, required, external } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import usePasswordValidation from '@/composables/usePasswordValidation.ts'
import { getPasswordFieldError } from '@/modules/auth/utils.ts'
import type { Code } from '@/types/code.ts'
import CardChangeMfaMethod from '@/modules/account/components/CardChangeMfaMethod.vue'
import type { SignInMethod } from '@/api/auth.ts'
import BtnChangeMfa from '@/components/BtnChangeMfa/BtnChangeMfa.vue'
import accountApi from '@/modules/account/api'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

type PasswordChangeForm = {
  old_password: string
  password: string
  code: Code
  client_id: string
}

const props = defineProps<{
  method?: SignInMethod
}>()

const captcha = inject(captchaInjectionKey) as Captcha
const modal = useModal()
const personal = usePersonalStore()
const { handleResponseException } = useFormHelpers()

const isSubmitting = ref(false)
const changingMethod = ref(false)

const method = ref(props.method ?? personal.preferableMfaMethod)

const form = reactive<PasswordChangeForm>({
  old_password: '',
  password: '',
  code: [],
  client_id: '',
})

const { strength } = usePasswordValidation(toRef(form, 'password'))

type Errors = { [key: string]: string } & Partial<PasswordChangeForm & { non_field_errors: string }>
const externalResults = reactive<Errors>({})
const rules = {
  old_password: [required()],
  password: [required()],
  code: [required(), mfaCode()],
  client_id: [external],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

async function sendCode(currentMethod?: SignInMethod) {
  try {
    await captcha.execute()
    changingMethod.value = false
    method.value = currentMethod ?? method.value

    const { data } = await accountApi.mfaCodeRequest({
      method: method.value,
      action: 'update_password',
      captcha_token: captcha.token.value,
    })

    form.client_id = data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults, { useToast: true })
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true

  v$.value.$clearExternalResults()
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }
  try {
    await personal.changePassword(form)
    modal.show('account-password-change-success', { isCustomStyle: true })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(sendCode)

// Clear validation results when code changes
watch(
  () => form.code,
  () => {
    v$.value.$reset()
    v$.value.$clearExternalResults()
  },
)
</script>

<template>
  <ModalCard
    v-if="!changingMethod"
    tag="form"
    :heading="$t('modules.account.modals.accountPasswordChange.heading')"
    @submit.prevent="submit"
  >
    <template #description>
      <div class="step">
        <p class="step-number text-heading-caps">
          {{ $t('modules.account.modals.accountPasswordChange.step1') }}
        </p>
        {{ $t('modules.account.modals.accountPasswordChange.description') }}
      </div>
    </template>

    <TextField
      v-model="form.old_password"
      :label="$t('modules.account.modals.accountPasswordChange.currentPassword')"
      type="password"
      :state="v$.old_password.$error ? 'error' : ''"
      :tip="v$.old_password.$errors?.[0]?.$message"
    />
    <TextField
      v-model="form.password"
      :label="$t('modules.account.modals.accountPasswordChange.newPassword')"
      type="password"
      :state="v$.password.$error ? 'error' : ''"
      :tip="getPasswordFieldError(v$.password)"
    >
      <PasswordStrength v-if="form.password" :strength="strength" />
    </TextField>

    <div class="spacer" />

    <div class="step">
      <p class="step-number text-heading-caps">
        {{ $t('modules.account.modals.accountPasswordChange.step2') }}
      </p>
      <p class="text-body-m">
        {{
          $t('modules.account.common.securityCodeSentToMethod', {
            method: method === 'email' ? personal.email : personal.phone,
          })
        }}
      </p>
    </div>

    <Pin
      class="pin"
      :state="v$.code.$error ? 'error' : ''"
      :tip="v$.code.$errors?.[0]?.$message"
      @input="form.code = $event"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <BtnResend @click="sendCode()" />
    <BtnChangeMfa @click="changingMethod = true" />
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>

  <CardChangeMfaMethod
    v-else
    :method="method"
    @canceled="changingMethod = false"
    @changed="sendCode"
  />
</template>

<style scoped lang="scss">
.pin {
  justify-self: center;
}

.step {
  gap: 1rem;
  display: grid;
}

@media screen and (min-width: $mobile) {
  .step .step-number {
    display: none;
  }
}
</style>
