<script setup lang="ts">
import { Banner, DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'
import { useMembersLocale } from '../locales'

const props = defineProps({
  confirm: Function,
  isProcessing: Boolean,
  count: Number,
})

const { t } = useMembersLocale()

const modal = useModal()

async function handleConfirm() {
  await props.confirm?.()
}
</script>

<template>
  <ModalCard
    tag="form"
    :heading="t('Bulk invite')"
    icon="users"
    icon-color="lavender"
    @submit.prevent="handleConfirm"
  >
    <p class="text-body-m">
      {{ t('doYouWantInviteMembers?', { count }) }}
      <Banner>
        {{ t('verifyRecipientsConsent') }}
      </Banner>
    </p>
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" :loading="isProcessing">{{ $t('buttons.send') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.banner {
  margin-top: 1.5rem;
}
</style>
