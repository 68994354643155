import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import toast from '@/libs/toast'
import { i18n } from '@keyo/core/i18n'

const cssRoot = document.querySelector(':root')
export const getCssVar = name => getComputedStyle(cssRoot).getPropertyValue(name)
export const setCssVar = (name, value) => cssRoot.style.setProperty(name, value)

export const trimObj = o =>
  Object.keys(o).forEach(k => {
    if (isString(o[k])) {
      o[k] = o[k].trim()
    } else if (isPlainObject(o[k])) {
      trimObj(o[k])
    }
  })

export const copy = async text => {
  try {
    if (!navigator.clipboard) {
      throw i18n.global.t('errors.browserDontSupportAction')
    }
    await navigator.clipboard.writeText(text)
    toast.show(() => `${i18n.global.t('common.copied')}!`, 'success')
  } catch (error) {
    toast.show(error, 'error')
  }
}

export const formatDob = dob => {
  if (!dob) return '-'
  return new Date(dob).toLocaleDateString(undefined, {
    timeZone: 'UTC',
  })
}
