import type { UseStorageOptions } from '@vueuse/core'
import { useStorage } from '@vueuse/core'
import { watch } from 'vue'

const storageOptions: UseStorageOptions<boolean> = {
  writeDefaults: false,
  mergeDefaults: true,
}

const storagePrefix = 'FEAT_FLAG_'

export const FEATURE_FLAGS = {
  ENABLE_BETA_LANGS: useStorage(
    `${storagePrefix}ENABLE_BETA_LANGS`,
    import.meta.env.APP_ENABLE_BETA_LANGS === 'true',
    localStorage,
    storageOptions,
  ),
}

// Reload the page when the feature flags are updated
watch(Object.values(FEATURE_FLAGS), () => {
  // if not 🐣
  if (window?.location?.pathname !== '/%F0%9F%90%A3') {
    window.location.reload()
  }
})

export const H_CAPTCHA_SITE_KEY = import.meta.env.APP_H_CAPTCHA_PUBLIC_SITE_KEY

// TODO: Remembers it is temporal config for Africa environment
export const IS_AFRICA_ENV = import.meta.env.APP_ENV === 'africa'
