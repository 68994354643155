<script setup lang="ts">
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { DialogBtn, Pin, Icon } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import { usePersonalStore } from '@/store'
import { useAuthN, useModal } from '@/composables'

import useFormHelpers from '@/composables/useFormHelpers'
import { external, mfaCode, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import type { DeleteByMethodBody } from '../pinia'
import usePoliciesUrl from '@/composables/usePoliciesUrl.ts'
import CardChangeMfaMethod from '@/modules/account/components/CardChangeMfaMethod.vue'
import type { SignInMethod } from '@/api/auth.ts'
import BtnChangeMfa from '@/components/BtnChangeMfa/BtnChangeMfa.vue'
import { getMethodI18nValue } from '../utils'
import accountApi from '@/modules/account/api'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

const props = defineProps<{
  method?: SignInMethod
}>()

const captcha = inject(captchaInjectionKey) as Captcha

const { termsOfUse } = usePoliciesUrl()
const modal = useModal()

const personal = usePersonalStore()
const auth = useAuthN()

const isSubmitting = ref(false)
const changingMethod = ref(false)

const { handleResponseException } = useFormHelpers()

const method = ref(props.method ?? personal.preferableMfaMethod)

const form = reactive<DeleteByMethodBody>({
  code: [],
  client_id: '',
})

type Errors = { [key: string]: string } & Partial<DeleteByMethodBody & { non_field_errors: string }>
const externalResults = reactive<Errors>({})
const rules = {
  code: [required(), mfaCode()],
  client_id: [external],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

async function sendCode(currentMethod?: SignInMethod) {
  try {
    if (!auth.isAuthenticated) return // Avoid throwing unauthenticated error on account deletion

    await captcha.execute()
    changingMethod.value = false
    method.value = currentMethod ?? method.value

    const response = await accountApi.mfaCodeRequest({
      method: method.value,
      action: 'delete_profile',
      captcha_token: captcha.token.value,
    })

    form.client_id = response?.data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true

  v$.value.$clearExternalResults()
  v$.value.$reset()

  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await personal.profileDelete(form)
    await auth.signOut()

    modal.show('account-delete-success', { isCustomStyle: true })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

watch(form, () => {
  v$.value.$clearExternalResults()
  v$.value.$reset()
})

onMounted(sendCode)
</script>

<template>
  <ModalCard
    v-if="!changingMethod"
    tag="form"
    icon="delete"
    icon-color="red"
    :heading="$t('common.deleteAccount')"
    @submit.prevent="submit"
  >
    <p class="text-body-m">
      {{
        $t('modules.account.modals.accountDelete.description.part1', {
          method: getMethodI18nValue(method),
        })
      }}
      <strong> {{ $t('common.deleteAccount') }} </strong>,
      {{ $t('modules.account.modals.accountDelete.description.part2') }}
      <a class="text-anchor" :href="termsOfUse" target="_blank">
        {{ $t('common.termsOfUse') }}
      </a>
    </p>
    <Pin
      class="pin"
      :state="v$.$error ? 'error' : ''"
      :tip="v$.code.$errors?.[0]?.$message || v$.client_id.$errors?.[0]?.$message"
      @input="form.code = $event"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <BtnResend @click="sendCode()" />
    <BtnChangeMfa @click="changingMethod = true" />

    <template #buttons>
      <button class="back-arrow" @click="modal.hide">
        <Icon name="left-3" />
      </button>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn danger :loading="isSubmitting" type="submit">
        {{ $t('common.deleteAccount') }}
      </DialogBtn>
    </template>
  </ModalCard>

  <CardChangeMfaMethod
    v-else
    :method="method"
    @canceled="changingMethod = false"
    @changed="sendCode"
  />
</template>

<style lang="scss" scoped>
.text-anchor {
  text-decoration-line: underline;
}

.pin {
  justify-self: center;
}
.back-arrow {
  display: none;
  align-items: center;
  justify-content: center;
  height: var(--header-height);
  aspect-ratio: 1;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  color: var(--color-grey-700);
}
@media all and (max-width: $mobile) {
  .back-arrow {
    display: flex;
  }
}
</style>
