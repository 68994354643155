<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'

const props = defineProps({
  confirm: Function,
  isProcessing: Boolean,
  methodName: String,
})

const modal = useModal()

function handleConfirm() {
  props.confirm?.()
}
</script>

<template>
  <ModalCard
    tag="form"
    :with-mobile-header-style="false"
    stick-to-bottom
    :heading="$t('Remove your address')"
    :description="$t('Are you sure you want to remove this address from your profile?')"
    icon="trash"
    icon-color="red"
    @submit.prevent="handleConfirm"
  >
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" danger :loading="isProcessing">
        {{ $t('Remove address') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
