<template>
  <div class="icon-box">
    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped>
.icon-box {
  width: 106px;
  height: 106px;
  box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.1);
  background: rgba(249, 249, 249, 0.6);
  border-radius: 10%;
  padding: 8px;
  margin-bottom: 40px;
}

.icon-box div {
  display: grid;
  place-content: center;
  background-color: #f9f9f9;
  box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.1);
  border-radius: 10%;
  width: 90px;
  height: 90px;
}
</style>