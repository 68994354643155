import _isString from 'lodash/isString'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js/min'

import { i18n } from '@keyo/core/i18n'

const isPossibleEmail = str => str.includes('@')

export const validations = {
  required(value, error = i18n.global.t('utils.validations.required.default')) {
    if (typeof value === 'number') return
    if (_isString(value)) {
      if (value.trim().length) return
    } else if (!_isEmpty(value)) {
      return
    } else if (typeof value === 'boolean' && value) {
      return
    }
    return error
  },
  letters(str) {
    if (!str?.length) return
    if (/^[a-zA-Z\s]*$/.test(str)) return
    return i18n.global.t('utils.validations.letters.default')
  },
  email(str) {
    if (!str?.length) return
    if (isEmail(str)) return
    return i18n.global.t('utils.validations.email.default')
  },
  phone: str => {
    if (!str?.length) return
    if (!isPossiblePhoneNumber(str)) return i18n.global.t('utils.validations.phone.possiblePhone')
    if (isValidPhoneNumber(str)) return
    return i18n.global.t('utils.validations.phone.default')
  },
  emailOrPhone: str => {
    if (!str?.length) return i18n.global.t('utils.validations.emailOrPhone.default')
    if (isEmail(str) || isValidPhoneNumber(str)) return
    if (isPossiblePhoneNumber(str)) {
      return i18n.global.t('utils.validations.emailOrPhone.formatPhone')
    }
    if (isPossibleEmail(str)) {
      return i18n.global.t('utils.validations.email.default')
    }
    return i18n.global.t('utils.validations.emailOrPhone.validEmailOrPhone')
  },
  max(str, length) {
    if (!str?.length) return
    return str.length > parseInt(length)
      ? i18n.global.t('utils.validations.max.default', { length })
      : undefined
  },
  min(str, length) {
    if (!str?.length) return
    return str.length < parseInt(length)
      ? i18n.global.t('utils.validations.min.default', { length })
      : undefined
  },
  match(str, to, name) {
    if (str === to) return
    return i18n.global.t('utils.validations.match.default', { name })
  },
  matchPassword(str, to, name) {
    const error = validations.match(str, to, name)
    if (!error) return

    return i18n.global.t('utils.validations.matchPassword.default')
  },
  confirm(str, toConfirm, name) {
    return toConfirm && !str
      ? i18n.global.t('utils.validations.confirm.default', { name })
      : undefined
  },
  password: str => {
    if (!str?.length) return i18n.global.t('utils.validations.password.enterPassword')
    if (
      str.length < 12 ||
      !/\d/.test(str) ||
      !/[!@#$%^&*()\-=+]/.test(str) ||
      !/[a-z]/.test(str) ||
      !/[A-Z]/.test(str)
    ) {
      return i18n.global.t('utils.validations.password.weakPassword')
    }
  },
  name: str => {
    if (!str?.length) return
    if (/^[a-zA-Z\u0080-\u024F\s]+(?:([-'`\u2019]|(\.\s))[a-zA-Z\u0080-\u024F\s]+)*$/.test(str))
      return
    return i18n.global.t('utils.validations.name.default')
  },

  url: str => {
    if (!str?.length) return
    if (
      !isURL(str, {
        allow_query_components: false,
        protocols: ['https'],
      })
    ) {
      return i18n.global.t('utils.validations.url.default')
    }
  },
  mfaCode: value => {
    try {
      if (!value) return
      if (!_isArray(value)) {
        throw i18n.global.t('utils.validations.mfaCode.shouldBeArray')
      }
      if (value.filter(v => /^\d$/.test(v)).length === 6) {
        return
      } else {
        throw 'e'
      }
    } catch (e) {
      return i18n.global.t('utils.validations.mfaCode.default')
    }
  },
}

/**
 * Validates value against provided list of checks and returns first failed one.
 * @param {("required" | "letters" | "email" | `max:${number}` | `min:${number}` | "name" | `phone` | `mfaCode` | `match:${string}`)[]} checks list of checks
 * @param {string} value to validate
 * @returns {(string | undefined)} failed validation string description or undefined
 */
export function validate(checks = [], value = '', context) {
  let error
  for (let i = 0; i < checks.length; i++) {
    const [v, option] = checks[i].split(':')

    switch (v) {
      case 'match':
      case 'confirm':
        error = validations[v]?.(value, context[option], option)
        break
      case 'matchPassword':
        error = validations?.matchPassword(value, context.password, option)
        break
      default:
        error = validations[v]?.(value, option)
    }

    if (error) return error
  }
}
