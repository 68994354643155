<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import { useModal } from '@/composables'
import useSecret from '../composables/useSecret'

const modal = useModal()
const secret = useSecret()
</script>

<template>
  <ModalCard
    size="m"
    :heading="$t('modules.apiCreds.modals.secretRotateConfirm.title')"
    :description="$t('modules.apiCreds.modals.secretRotateConfirm.description')"
    :with-mobile-header-style="false"
    icon="update"
    icon-color="yellow"
  >
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="secret.isProcessing.value" @click="secret.create">
        {{ $t('modules.apiCreds.rotate') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
