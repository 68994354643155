<script lang="ts" setup>
import type { IconType } from '@keyo/ui'
import { Anchor, IconBox, Radio } from '@keyo/ui'
import { computed } from 'vue'
import type { SignInMethod } from '@/api/auth.ts'
import { nanoid } from 'nanoid'
import { getMethodI18nValue } from '@/modules/account/utils'

interface Props {
  modelValue: SignInMethod
  method: SignInMethod
  label: string
  verify?: boolean
  tag?: string
}

const iconsByMethod: Record<SignInMethod, IconType> = {
  email: 'email',
  phone: 'call-phone',
}

const props = defineProps<Props>()

defineEmits(['start-verification'])

const model = defineModel<SignInMethod>()

const RADIO_ID = nanoid()

const methodName = computed(() => getMethodI18nValue(props.method))
</script>

<template>
  <component :is="tag ?? 'li'" class="mfa-method">
    <label :for="RADIO_ID" class="method-label">
      <IconBox color="grey" :name="iconsByMethod[method]" />

      <span class="info">
        <span class="text-label">
          {{ label }}
        </span>
        <span class="text-body-xs">
          {{ $t('modules.account.pages.securityMfa.receiveCodeMethod', { method: methodName }) }}
        </span>

        <slot name="info" />
      </span>

      <Radio
        v-if="!verify"
        :id="RADIO_ID"
        v-model="model"
        :value="method"
        name="mfa-method"
        class="action"
        size="s"
      />
      <Anchor v-else type="button" tag="button" class="action" @click="$emit('start-verification')">
        {{ $t('buttons.verify') }}
      </Anchor>
    </label>
  </component>
</template>

<style lang="scss" scoped>
.mfa-method {
  .method-label {
    display: flex;
    gap: 1rem;
    padding-block: 1rem;
    align-items: center;

    .info {
      display: grid;
      text-align: left;
    }

    .method-label {
      display: grid;
    }

    .action {
      margin-left: auto;
    }
  }
}

@media screen and (min-width: $mobile) {
  .mfa-method {
    max-width: 34rem;
  }
}
</style>
