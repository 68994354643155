import type { SignInMethod } from '@/api/auth.ts'
import { i18n } from '@keyo/core/i18n'
import type { FieldError } from '@keyo/core/validations'
import type { Stringable } from '@keyo/core'
import type { ErrorObject } from '@vuelidate/core'
import { isObject } from '@vueuse/core'

export const getSignInMethodFromType = (type: string): SignInMethod =>
  type.includes('@') ? 'email' : 'phone'

type MaybeVuelidateEntity = {
  $error: boolean
  $errors: ErrorObject[]
}
/**
 * This will be removed once we migrate all the forms to useForm,
 * there is duplication of code but is to avoid breaking changes and just delete in the future
 * @deprecated
 */
export const handlePasswordFieldVuelidate = ({ $error, $errors }: MaybeVuelidateEntity) => {
  if (!$error) {
    return ''
  }

  const externalError = $errors?.[0]?.$message as Stringable
  const parsedExternalError = externalError as FieldError

  // 2051: Ensure this field has at least 9 characters.
  if (parsedExternalError.code === 2051) {
    return i18n.global.t('errors.weakPassword')
  }

  return externalError
}

export const getPasswordFieldError = (errorMessage?: Stringable | MaybeVuelidateEntity) => {
  if (!errorMessage) {
    return ''
  }

  if (isObject(errorMessage) && '$error' in (errorMessage as MaybeVuelidateEntity)) {
    return handlePasswordFieldVuelidate(errorMessage as MaybeVuelidateEntity)
  }

  const parsedError = errorMessage as FieldError

  // 2051: Ensure this field has at least 9 characters.
  if (parsedError?.code === 2051) {
    return i18n.global.t('errors.weakPassword')
  }

  return errorMessage
}

export const buildQueryFromSubQuery = (subquery: string): Record<string, string> => {
  const queryData: Record<string, string> = {}
  const queryString = subquery.split('?')[1]
  for (const [key, value] of new URLSearchParams(queryString).entries()) {
    queryData[key] = value
  }

  return queryData
}
