<script setup lang="ts">
import useModal from '@/composables/useModal'
import PhoneAddCode from '@/modules/account/components/PhoneAddCode.vue'

const props = defineProps({
  clientId: {
    type: String,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
  heading: {
    type: String,
  },
  description: {
    type: String,
  },
  onSuccess: {
    type: Function,
  },
})

const modal = useModal()

const handleSubmitted = () => {
  if (props.onSuccess) {
    modal.close()
    props.onSuccess()
    return
  }

  modal.show('account-phone-add-success', {
    isCustomStyle: true,
  })
}
</script>

<template>
  <PhoneAddCode
    v-if="phone && clientId"
    icon="call-phone"
    :client-id="clientId"
    :phone="phone"
    :heading="heading"
    :description="description"
    @finished="handleSubmitted"
    @canceled="modal.close()"
  />
</template>
