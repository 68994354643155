<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'

const props = defineProps({
  confirm: Function,
  isProcessing: Boolean,
  methodName: String,
})

const modal = useModal()

function handleConfirm() {
  props.confirm?.()
}
</script>

<template>
  <ModalCard
    tag="form"
    :with-mobile-header-style="false"
    stick-to-bottom
    :heading="$t('modules.account.modals.accountSecurityMfaConfirm.change2faAuthentication')"
    :description="
      $t('modules.account.modals.accountSecurityMfaConfirm.areYouSureYouWantToChange2fa', {
        method: methodName,
      })
    "
    icon="email"
    icon-color="lavender"
    @submit.prevent="handleConfirm"
  >
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" :loading="isProcessing">
        {{
          $t('modules.account.modals.accountSecurityMfaConfirm.changeTo', { method: methodName })
        }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
