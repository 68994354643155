<script setup lang="ts">
import { watch, inject, onBeforeMount, reactive, ref } from 'vue'

import type { AxiosError } from 'axios'
import { DialogBtn, Pin } from '@keyo/ui'
import { usePersonalStore } from '../pinia'
import type { EmailSetBody } from '../pinia'

import useInputSize from '@/composables/useInputSize'
import useFormHelpers from '@/composables/useFormHelpers'

import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'

import BtnResend from '@/components/BtnResend/BtnResend.vue'
import { mfaCode, email, required, external } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import { type Captcha, captchaInjectionKey } from '@keyo/core'
import accountApi from '@/modules/account/api'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import useModal from '@/composables/useModal.ts'

const props = defineProps({
  clientId: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
})

const captcha = inject(captchaInjectionKey) as Captcha

const modal = useModal()
const personalStore = usePersonalStore()
const { setEmail } = personalStore

const { handleResponseException } = useFormHelpers()

const isSubmitting = ref(false)

const size = useInputSize()

const form = reactive<EmailSetBody & { captcha_token: string }>({
  email: '',
  code: [],
  client_id: '',
  captcha_token: '',
})

type Errors = { [key: string]: string } & Partial<typeof form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  code: [required(), mfaCode()],
  email: [required(), email()],
  client_id: [required()],
  captcha_token: [external],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

const resendCode = async () => {
  try {
    await captcha.execute()

    form.captcha_token = captcha.token.value

    const { data } = await accountApi.mfaCodeRequest({
      method: 'email',
      email: form.email,
      action: 'confirm_email',
      captcha_token: form.captcha_token,
    })

    form.client_id = data.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

const submit = async () => {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await v$.value.$validate()
    if (v$.value.$error) {
      isSubmitting.value = false
      return
    }

    await setEmail(form)
    modal.show('AccountEmailAddSuccess', {
      isCustomStyle: true,
    })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onBeforeMount(() => {
  form.client_id = props.clientId as string
  form.email = props.email as string
})

// Clear validation results when code changes
watch(
  () => form.code,
  () => {
    v$.value.$reset()
    v$.value.$clearExternalResults()
  },
)
</script>

<template>
  <ModalCard
    tag="form"
    size="m"
    icon="email-ground"
    icon-color="lavender"
    :heading="$t('Add email')"
    :description="$t('modules.account.common.securityCodeSentToMethod', { method: form.email })"
    @submit.prevent="submit"
  >
    <Pin
      :size="size"
      :disabled="isSubmitting"
      class="pin"
      @input="form.code = $event"
      @complete="submit"
    />
    <NonFieldErrors :errors="v$.code.$errors?.[0]?.$message" />

    <BtnResend @click="resendCode()" />

    <NonFieldErrors :errors="externalResults.captcha_token" />
    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped lang="scss">
.pin {
  justify-self: center;
}
</style>
