<script setup lang="ts">
import ModalCardSuccess from '@/components/modals/components/ModalCardSuccess.vue'
import usePoliciesUrl from '@/composables/usePoliciesUrl.ts'

const { termsOfUse, privacyPolicy } = usePoliciesUrl()
</script>

<template>
  <ModalCardSuccess :heading="$t('modules.account.modals.accountDeleteSuccess.heading')">
    <p class="text-body-m">
      {{ $t('modules.account.modals.accountDeleteSuccess.description') }}
      <a :href="termsOfUse" target="_blank">
        {{ $t('common.termsOfUse') }}
      </a>
      {{ $t('common.and') }}
      <a :href="privacyPolicy" target="_blank">
        {{ $t('common.privacyPolicy') }}
      </a>
    </p>
  </ModalCardSuccess>
</template>

<style lang="scss" scoped>
a {
  text-decoration: underline;
  font-weight: 500;
}
</style>
