import { formatDob } from '@/utils'

export const jwt = {
  getBody(jwt) {
    const [, body] = jwt.split('.')
    return JSON.parse(window.atob(body))
  },
  getUntilExpiration(jwt) {
    const { exp } = this.getBody(jwt)
    if (!exp) throw Error('JWT body is missing "exp" field')
    return exp - Math.floor(Date.now() / 1000)
  },

  isExpired(jwt) {
    try {
      return this.getUntilExpiration(jwt) <= 3 // treat as already expired when few seconds left until expiration.
    } catch (e) {
      // invalid tokens parsing exception, treat as expired
      return true
    }
  },
}

export const getUserInitials = user => {
  return (
    user?.first_name || user?.last_name
      ? `${user?.first_name?.[0] || ''}${user?.last_name?.[0] || ''}`
      : user?.email
      ? `${user?.email?.[0] || ''}${user?.email?.[1] || ''}`
      : ''
  ).toUpperCase()
}

export const prepareUser = ({ user, organization_role, member_id, status }) => ({
  ...user,
  role: organization_role,
  id: member_id,
  initials: getUserInitials(user),
  status,
  displayDob: formatDob(user.date_of_birth),
})
