import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { BIOMETRIC_POLICY, KEYO_WEB, PRIVACY_POLICY, TERMS_OF_USE } from '@/constants/urls'

export type POLICIES = 'terms-of-use' | 'privacy-policy' | 'biometric-policy'

export default function usePoliciesUrl() {
  const { locale } = useI18n()

  const isEnglish = computed(() => locale.value === 'en')

  const computedUrl = (defaultUrl: string, policyUrl: POLICIES) =>
    computed(() => (isEnglish.value ? defaultUrl : `${KEYO_WEB}/${locale.value}/${policyUrl}`))

  const termsOfUse = computedUrl(TERMS_OF_USE, 'terms-of-use')
  const privacyPolicy = computedUrl(PRIVACY_POLICY, 'privacy-policy')
  const biometricPolicy = computedUrl(BIOMETRIC_POLICY, 'biometric-policy')

  return {
    termsOfUse,
    privacyPolicy,
    biometricPolicy,
  }
}
