<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import { I18nT } from 'vue-i18n'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'
import { useMembersLocale } from '../locales'

const props = defineProps({
  confirm: Function,
  isProcessing: Boolean,
  data: String,
})

const { t } = useMembersLocale()

const modal = useModal()
</script>

<template>
  <ModalCard
    tag="form"
    :heading="t('org.member.inviteMember')"
    icon-color="lavender"
    icon="user"
    @submit.prevent="props.confirm?.()"
  >
    <template #description>
      <I18nT keypath="doYouWantInviteData?">
        <template #data>
          <strong>{{ data }}</strong>
        </template>
      </I18nT>
    </template>

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" :loading="isProcessing">{{ $t('buttons.send') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.banner {
  margin-top: 1.5rem;
}
</style>
