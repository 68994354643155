<script setup>
import { onMounted } from 'vue'
import useCountdown from '@/composables/useCountdown'
import { Anchor } from '@keyo/ui'

const props = defineProps({
  countdown: { type: Number, default: 50 },
})

const cd = useCountdown(props.countdown)
onMounted(() => cd.start())

function click() {
  cd.restart()
}
</script>

<template>
  <Anchor type="button" tag="button" class="anchor" :disabled="cd.remaining.value" @click="click">
    <template v-if="cd.remaining.value > 0">
      {{
        $t('components.btnResend.resendSecurityCodeIn', {
          remaining: cd.remaining.value,
        })
      }}
    </template>

    <template v-else>
      {{ $t('components.btnResend.resendSecurityCode') }}
    </template>
  </Anchor>
</template>

<style lang="scss" scoped>
.anchor {
  justify-self: center;
}
</style>
