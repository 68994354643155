export default [
  {
    path: '/organizations/:id/devices',
    meta: {
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '',
        name: 'org.devices',
        component: () => import('@/pages/organization/devices/DevicesList.vue'),
      },
      {
        path: '/organizations/:id/devices/:deviceId',
        name: 'org.device',
        component: () => import('@/pages/organization/devices/DeviceProfile.vue'),
      },
    ],
  },
]
