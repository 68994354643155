<script>
import toast from '@/libs/toast'

import { Btn, TextField } from '@keyo/ui'
import IconBox from '@/components/IconBox'

import { mapActions } from 'pinia'
import { useWebhooksStore } from '../pinia'
import { useModal } from '@/composables'
import useFormHelpers from '@/composables/useFormHelpers'
import useForm from '@/composables/useForm'
import { required } from '@keyo/core/validations'

export default {
  components: { Btn, TextField, IconBox },
  setup() {
    const initialForm = {
      url: '',
      secret_token: '',
    }

    const validationRules = {
      url: [required()],
    }

    const { form, errors, submitForm, isSubmitting } = useForm(initialForm, validationRules)
    const modal = useModal()

    const { handleResponseException } = useFormHelpers()

    return {
      form,
      errors,
      submitForm,
      isSubmitting,
      handleResponseException,
      modal,
      toast: toast.show,
    }
  },
  methods: {
    ...mapActions(useWebhooksStore, ['create']),
    async submit() {
      await this.submitForm(async () => {
        await this.create(this.$route.params.id, this.form)
        this.modal.hide()
      })
    },
  },
}
</script>

<template>
  <form class="webhook-create__container" @submit.prevent="submit">
    <IconBox>
      <img src="../assets/icons/webhook-add.svg" :alt="$t('modules.webhooks.plusIcon')" />
    </IconBox>
    <h1 class="webhook-create__title">{{ $t('modules.webhooks.webhookCreation') }}</h1>
    <p class="webhook-create__text">
      {{ $t('modules.webhooks.setupInfo') }}
    </p>

    <TextField
      id="url"
      v-model="form.url"
      :state="errors.url ? 'error' : ''"
      :tip="errors.url"
      :label="$t('modules.webhooks.endpointURL')"
    />

    <TextField
      id="secret"
      v-model="form.secret_token"
      type="password"
      :state="errors.secret_token ? 'error' : ''"
      :tip="errors.secret_token"
      :label="$t('modules.webhooks.secret')"
    />
    <div class="webhook-create__buttons">
      <Btn type="submit" :loading="isSubmitting">
        {{ $t('modules.webhooks.createEndpoint') }}
      </Btn>
      <Btn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
:deep(.text-field-container) {
  margin-bottom: 1rem;
}

.webhook-create__container {
  width: 100%;
  max-width: 620px;
}
.webhook-create__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 20px 0;
}
.webhook-create__text {
  font-size: 16px;

  margin: 0 0 18px 0;
}
.webhook-create__buttons {
  display: flex;
  gap: 1rem;
}
</style>
