import users from './users'
import devices from './devices'
import devTools from './dev-tools'
import integrations from './integrations'

export default [
  {
    // TODO: Once the API provides the functionality, add unique organization slugs instead e.g /my-org-name /cool-org etc.
    // https://linear.app/keyoid/issue/API-496/implement-slugs-for-entities
    path: '/organizations/:id',
    name: 'org',
    redirect: { name: 'org.overview' },
    meta: {
      layout: 'DashboardLayout',
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '/organizations/:id/overview',
        name: 'org.overview',
        component: () => import('@/modules/admins/pages/home/OrganizationHome.vue'),
        meta: {
          requiresOrgAccess: true,
        },
      },
      {
        path: '/organizations/:id/settings',
        component: () => import('@/pages/organization/Settings.vue'),
        meta: {
          requiresOrgAccess: true,
        },
        children: [
          {
            path: '',
            name: 'org.settings.profile',
            component: () => import('@/pages/organization/OrganizationProfile.vue'),
          },
          {
            path: 'roles',
            name: 'org.settings.roles',
            component: () => import('@/modules/admins/pages/Roles.vue'),
          },
        ],
      },
      ...users,
      ...devices,
      ...devTools,
      ...integrations,
    ],
  },
]
