<script>
import modals from './modals'
import useModal, { modalState } from '@/composables/useModal'
import { watch, computed, onMounted, onBeforeUnmount } from 'vue'
import { setCssVar } from '@/utils'
import { useScrollLock } from '@vueuse/core'

export default {
  components: modals,
  setup() {
    const modal = useModal()

    const handleEscPress = ev => {
      ev.key === 'Escape' && modal.hide()
    }
    const bodyScrollLock = useScrollLock(document.body)

    function handlePopState() {
      modal.hide()
    }
    onMounted(() => {
      addEventListener('popstate', handlePopState)
    })
    onBeforeUnmount(() => {
      removeEventListener('popstate', handlePopState)
    })

    watch(modalState, () => {
      if (modalState.value?.component) {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth
        setCssVar('--modal-scroll-width-adjust', `${scrollbarWidth}px`)
        addEventListener('keyup', handleEscPress, { passive: true })
        bodyScrollLock.value = true
      } else {
        removeEventListener('keyup', handleEscPress, { passive: true })
      }
    })

    // while migrating to new DS modals. Usage: modal.show('awesome-popup', { isCustomStyle: true })
    const modalClass = computed(() => ({
      'modal-card': !modalState.value?.props?.isCustomStyle,
    }))

    const handleAfterLeave = () => {
      bodyScrollLock.value = false
      setCssVar('--modal-scroll-width-adjust', 0)
    }
    return {
      modalState,
      modal,
      handleAfterLeave,
      modalClass,
    }
  },
}
</script>

<template>
  <transition name="modal" @after-leave="handleAfterLeave">
    <div v-if="modalState?.component" :key="modalState?.id" class="modal__container inset-0">
      <div class="modal__overlay inset-0" tabindex="-1" @click="modal.hide" />
      <component
        :is="modalState?.component"
        :key="modalState?.id"
        v-bind="modalState?.props"
        class="modal"
        :class="modalClass"
        role="dialog"
        aria-modal="true"
      >
        <template v-for="(slot, name) in modalState?.slots" #[name] :key="name">
          <component :is="slot" />
        </template>
      </component>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.modal__container {
  position: fixed;
  z-index: var(--z-modal);
  display: grid;
  place-items: center;
  height: 100%;
}

.modal__overlay {
  position: fixed;
  background: var(--rbga-overlay);
  z-index: var(--z-overlay);
}

.modal {
  transition: all 0.25s ease;
  overflow-y: auto;
  max-height: 96vh;
}

.modal-card {
  background: var(--color-white);
  border-radius: 2rem;
  padding: 2rem 1.5rem 1.5rem;
}

.modal-enter-from,
.modal-leave-to {
  .modal__overlay {
    opacity: 0;
  }
  .modal {
    transform: scale(0.8);
    opacity: 0;
  }
}
.modal-enter-active {
  transition: opacity 0.25s ease-out;
  .modal__overlay {
    transition: all 0.25s ease-in;
  }
  .modal {
    transition: all 0.25s ease-in;
  }
}

.modal-leave-active {
  transition: opacity 0.15s ease-out;
  .modal__overlay {
    transition: all 0.15s ease-in;
  }
  .modal {
    transition: all 0.15s ease-in;
  }
}

.modal-leave-from,
.modal-enter-to {
  .modal__overlay {
    opacity: 1;
  }
  .modal {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
