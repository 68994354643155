<script setup lang="ts">
import { Btn, DialogBtn, TextField } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables'
import { copy } from '@/utils'

defineProps<{ secret: string }>()

const modal = useModal()
</script>

<template>
  <ModalCard
    size="m"
    :heading="$t('modules.apiCreds.createSecretKey')"
    :description="$t('modules.apiCreds.modals.secretCreated.description')"
    :with-mobile-header-style="false"
    icon="key-filled"
  >
    <div class="secret-box">
      <TextField :model-value="secret" class="text-field" type="password" size="m" readonly />
      <Btn icon="copy" size="m" @click="copy(secret)" />
    </div>

    <template #buttons>
      <DialogBtn @click="modal.hide">{{ $t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
.secret-box {
  display: flex;
  gap: 0.75rem;
}
.text-field {
  flex: 1;
}
</style>
