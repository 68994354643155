<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import toast from '@/libs/toast'

import { TextField, Btn } from '@keyo/ui'
import IconBox from '@/components/IconBox'
import UserInfo from '../components/UserInfo.vue'
import TableBox from '@/components/tables/TableBox.vue'
import RoleBadge from '@/components/RoleBadge/RoleBadge.vue'
import MemberStatus from '@/components/MemberStatus/MemberStatus.vue'

import { useModal } from '@/composables'
import { useAdminsStore } from '../pinia'
import { i18nUtils } from '@keyo/core/i18n'
import { useRoute } from 'vue-router'

let searchTimeout = null

const modal = useModal()
const route = useRoute()
const adminsStore = useAdminsStore()
const search = ref('')
const isLoaded = ref(false)
const requestingCodeForId = ref(null)

// Computed property
const validAdmins = computed(() => adminsStore.items.filter(a => a.status.name === 'ACTIVE'))

// Methods
const retrieveAdmins = async () => {
  await adminsStore.fetchList(route.params.id)
  isLoaded.value = true
}

const requestTransferCode = async nextOwner => {
  try {
    requestingCodeForId.value = nextOwner.id
    modal.show('transfer-ownership-confirm', { nextOwner })
  } catch (e) {
    console.error(e)
    toast.show(i18nUtils.errorSomethingBroken, 'error')
  } finally {
    requestingCodeForId.value = null
  }
}

watch(search, value => {
  clearTimeout(searchTimeout)
  searchTimeout = setTimeout(() => {
    adminsStore.fetchList(route.params.id, value)
  }, 300)
})

onMounted(() => {
  retrieveAdmins()
})
</script>

<template>
  <div class="ownership-modal">
    <h1>{{ $t('modules.admin.transferOwnership') }}</h1>
    <p class="desc">{{ $t('modules.admin.selectAdmin') }}</p>
    <TableBox>
      <div class="search-box">
        <TextField
          id="search"
          v-model="search"
          size="m"
          class="search-field"
          :placeholder="$t('common.search')"
          icon-leading="search"
        />
      </div>
      <div class="table-wrapper">
        <section class="table">
          <div class="text-heading-xs">{{ $t('common.fullName') }}</div>
          <div class="text-heading-xs">{{ $t('common.role') }}</div>
          <div class="text-heading-xs">{{ $t('common.status') }}</div>
          <div />
          <div v-if="isLoaded && !validAdmins.length" class="table-empty">
            <IconBox class="icon-box"><img src="../assets/crown-add.svg" /></IconBox>
            <div class="empty-desc">
              <h2>{{ $t('modules.admin.noAdmins') }}</h2>
              <p>{{ $t('modules.admin.setOrInviteAdmin') }}</p>
            </div>
          </div>
          <template v-for="item in validAdmins" v-else :key="item.id">
            <UserInfo :user="item" />
            <div><RoleBadge :role="item.role" /></div>
            <div><MemberStatus :status="item.status" /></div>
            <div>
              <Btn
                icon="arrow-right"
                size="s"
                :is-disabled="!!requestingCodeForId"
                :is-processing="requestingCodeForId === item.id"
                @click="requestTransferCode(item)"
              />
            </div>
          </template>
        </section>
      </div>
    </TableBox>
    <Btn kind="minimal" class="btn-cancel" @click="modal.hide">
      {{ $t('buttons.cancel') }}
    </Btn>
  </div>
</template>

<style lang="scss" scoped>
.ownership-modal {
  display: flex;
  flex-direction: column;
  width: 94%;
  max-width: 1200px;
  max-height: 90vh;
  min-height: 80vh;

  .desc {
    margin-bottom: 23px;
  }

  .btn-cancel {
    align-self: flex-start;
    flex: 0 0 auto;
    margin-top: auto;
  }
  :deep(.search-field) {
    margin: 0;
    max-width: 20rem;
    input {
      background-color: var(--color-white);
    }
  }
}

.table-wrapper {
  border: #dedede solid 1px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 6px;

  .table {
    border-radius: 6px;
    display: grid;
    grid-auto-rows: minmax(56px, min-content);
    grid-template-columns: auto min(115px) min(110px) 58px;
    grid-gap: 1px;
    gap: 1px;
    background-color: var(--color-grey-med);
    overflow: auto;

    :deep(> *) {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      background-color: var(--color-white);
    }

    :deep(> :nth-child(-n + 4)) {
      top: 0;
      position: sticky;
      background-color: var(--color-gray-10);
      z-index: 1;
    }

    .table-empty {
      padding: 56px 0;
      grid-column: 1/5;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-box {
        margin: 0 18px 0 0;
      }

      .empty-desc {
        max-width: 225px;
        font-size: 12px;
      }
    }
  }
}
</style>
