<script setup lang="ts">
import { Status, type StatusKind } from '@keyo/ui'
import { computed } from 'vue'
import type { UserStatus } from '@/types/user.ts'

interface Props {
  status: UserStatus
}
const props = defineProps<Props>()

const statusKinds: Record<typeof props.status.name, StatusKind | undefined> = {
  ACTIVE: 'positive',
  PAUSED: 'warning',
  DEACTIVATED: 'alert',
  INVITATION_SENT: undefined,
} as const

const statusKind = computed(() => statusKinds[props.status.name])
</script>

<template>
  <Status :kind="statusKind">{{ $t(`org.member.status.${status.name}`) }}</Status>
</template>
