export default {
  'Bulk invite': 'Bulk invite',
  'toInviteMemberCompleteForm': 'To invite a member, complete the form below.',
  'selectRole': 'Select the members organization role',
  'Download CSV template': 'Download CSV template',
  'fillOutTemplate': 'Download and fill out this template to invite members.',
  'Download': 'Download',
  'Upload CSV file': 'Upload CSV file',
  'ensureFilledCorrectly': 'Ensure the file is filled correctly and contains no errors.',
  'acceptsCSVfile': 'Accepts CSV file less than 5MB',
  'issuesWithContacts':
    "We've identified contacts in your { fileName } file that are unable to receive invitations. Download the file to update contacts containing errors, or proceed to send invitations solely to contacts without errors.",
  'noneContactToInvite':
    'None of the contacts in your { fileName } file are able to receive the invitation. Please upload the file again to update the contacts with errors.',
  'Download error report': 'Download error report',
  'ignoreRows': 'Ignore all rows with errors',
  'verifyRecipientsConsent':
    'Please verify that all recipients have given their clear consent to receive Keyo enrollment emails to ensure compliance with relevant data and privacy protection laws.',
  'doYouWantInviteData?': 'Do you want to invite { data } to join your organization?',
  'doYouWantInviteMembers?': 'Do you want to invite { count } members to join your organization?',
  'inviteHasSent': 'The invitation has been sent to { method }',
  'invitesAreSent': 'The invitations have been sent to the users.',
  // bulk error report csv file name and headings
  'members-bulk-invitation-error-report': 'members-bulk-invitation-error-report',
  'Member email': 'Member email',
  'Member role': 'Member role',
  'Error': 'Error',
  'member': 'member | members',
  'removeMember?': "Remove @:{'member'}?",
  'areYouSureToRemove': 'Are you sure you want to remove',
  'removingMemberOnlyRevokesAccess':
    'Removing a @:member from the organization only revokes their access to your organization and will not delete their account.',
  'membersRemoved': '@.capitalize:member has been removed from your organization.',
}
