import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { DropdownOption } from '@keyo/ui'
import { CountriesService } from '@keyo/core'

export function useCountries() {
  const countriesMap = CountriesService.getCountries()
  const { locale } = useI18n()

  const countriesOptions = computed<DropdownOption[]>(() =>
    Array.from(countriesMap.values())
      .map(country => ({
        id: country.iso2,
        label: country.name(),
        value: country.iso2,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, locale.value)),
  )

  return { countriesOptions }
}
