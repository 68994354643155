import { defineStore } from 'pinia'
import admins from '../api'
import { prepareUser } from '@/api/utils'
import { useOrganizationsStore } from '@/store'


export default defineStore('admins', {
  state: () => ({
    items: [],
    byId: {},

  }),
  actions: {
    async fetchList(orgId, search) {
      try {
        const resp = await admins.list(orgId, search)
        this.$patch(state => {
          this.items = resp.data.results.map(prepareUser)
          state.items.forEach(itm => {
            this.byId[itm.id] = itm
          })
        })
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },
    async transferOwnership(orgId, body) {
      const organizations = useOrganizationsStore()
      try {
        await admins.ownerTransfer(orgId, body)
        //TODO set org from response
        await organizations.fetchById(orgId)
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },
  },
})
