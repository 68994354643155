import { apiV3 } from '@/api'

type TokenResponse = {
  zendesk_token: string
  mobile_zendesk_token: string
}

export default {
  getJwt: () => apiV3.get<TokenResponse>('auth/integrations/zendesk/'),
}
