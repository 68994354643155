import { onBeforeUnmount, ref, watch } from 'vue'

interface CountdownOptions {
  onComplete?: () => void
}

function useCountdown(seconds: number, options: CountdownOptions = {}) {
  const remaining = ref(seconds)
  let intervalId: ReturnType<typeof setInterval>

  function start() {
    clearInterval(intervalId)

    intervalId = setInterval(() => {
      if (remaining.value === 0) {
        clearInterval(intervalId)
        options.onComplete?.()
      } else {
        remaining.value--
      }
    }, 1000)
  }

  function pause() {
    clearInterval(intervalId)
  }

  function restart() {
    pause()
    remaining.value = seconds
    start()
  }

  watch(remaining, () => {
    if (remaining.value === seconds) {
      start()
    }
  })

  onBeforeUnmount(() => {
    pause()
  })

  return {
    remaining,
    start,
    pause,
    restart,
  }
}

export default useCountdown
