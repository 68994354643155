import { markRaw, ref, type Component } from 'vue'

interface DrawerProps {
  [key: string]: any
}
interface DrawerState {
  component: Component
  props?: DrawerProps
}

export const _drawerState = ref<DrawerState | null>()

export default function () {
  const open = (component: Component, props?: DrawerProps) => {
    _drawerState.value = {
      component: markRaw(component),
      props,
    }
  }

  const close = () => {
    _drawerState.value = null
  }
  return {
    open,
    show: open,
    close,
    hide: close,
  }
}
