<script>
import { states, inputAttrsNames } from './model'

export default {
  name: 'TextField',
  inheritAttrs: false,
  props: {
    modelValue: [String, Number],
    isDisabled: Boolean,
    isSearch: Boolean,
    state: {
      type: String,
      validator: value => states.includes(value),
      default: '',
    },
    id: { type: String, required: true },
    tip: { type: String, default: '' },
    label: { type: String, default: 'Label' },
    tooltip: { type: String, default: '' },
    capitalize: { type: Boolean, default: false },
  },

  emits: ['update:modelValue'],

  setup(props, { attrs }) {
    const { name, ...iAttrs } = inputAttrsNames.reduce((ia, name) => {
      ia[name] = attrs[name]
      return ia
    }, {})
    const isPassword = iAttrs.type === 'password'
    return {
      name: name || props.id,
      iAttrs,
      isPassword,
    }
  },

  data() {
    return {
      value: '',
      inputType: this.iAttrs.type,
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue || this.value
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.value = value
      },
    },
    notEmpty() {
      return !!(this.modelValue || this.value)
    },
    classList() {
      return [
        this.$attrs.class,
        this.state,
        { 'text-field--disabled': this.isDisabled },
        { 'not-empty': this.notEmpty },
        { 'no-tooltip': this.tooltip.length < 1 },
      ]
    },
  },
  methods: {
    handleInput({ target }) {
      let value = ''
      if (this.capitalize && target.value.length) {
        value = target.value[0].toUpperCase() + target.value.slice(1)
      } else {
        value = target.value
      }
      this.model = value
    },
    showPassword() {
      if (this.inputType === 'text') {
        this.inputType = 'password'
        return
      }
      this.inputType = 'text'
    },
    clearInput() {
      this.model = ''
    },
  },
}
</script>

<template>
  <div :data-tooltip="tooltip" data-flow="top" class="text-field" :class="classList">
    <img v-if="isSearch" src="@/assets/icons/search.svg" alt="search" class="left-icon" />
    <input
      v-bind="iAttrs"
      :id="id"
      :name="name"
      :type="inputType"
      :disabled="isDisabled"
      :value="model"
      @input="handleInput"
    />
    <label class="label" :for="id">{{ label }}</label>
    <template v-if="notEmpty">
      <button v-if="isPassword" type="button" class="clear-btn" @click="showPassword">
        <img class="eye-icon" src="@/assets/icons/eye.svg" alt="eye" />
      </button>
      <button v-else type="button" class="clear-btn" @click="clearInput">
        <img src="@/assets/icons/cross.svg" alt="cross" />
      </button>
      <div class="mz-date-clear-hide" />
    </template>

    <span v-if="tip.length > 0" class="tip">{{ tip }}</span>
  </div>
</template>

<style scoped lang="scss">
.text-field {
  position: relative;
  height: 3rem;
  width: 100%;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem; // to keep space for error message
  background-color: var(--color-grey-200);

  .label {
    cursor: text;
    transform-style: preserve-3d;
    position: absolute;
    z-index: 0;
    top: 50%;
    overflow: hidden;
    left: 1rem;
    opacity: 0.5;
    backface-visibility: hidden;
    will-change: transform;
    transform-origin: 0 50% 0;
    transform: translate3d(0, -50%, 0) scale3d(1, 1, 1);
    transition: transform var(--easeOutQuint) 0.2s;

    -webkit-font-smoothing: antialiased;
  }

  input {
    padding: 1.5rem 2.5rem 0.5rem 1rem;
    width: 100%;
    height: 3rem;
    border-radius: 0.75rem;
    outline: none;
    color: var(--color-text);
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    top: 50%;
    transform: translateY(-50%);
    right: 3%;
    position: absolute;
    cursor: pointer;
  }

  input:focus {
    box-shadow: 0 0 0 1px var(--color-gray-30);
  }

  input:disabled {
    color: var(--color-gray-80);
  }

  input:focus ~ .label,
  &.not-empty .label {
    transform: translate3d(0, -100%, 0) scale3d(0.8, 0.8, 1);
  }

  input[type='date'],
  input[type='date']::-webkit-datetime-edit-fields-wrapper {
    opacity: 0;
    transition: opacity var(--easeOutQuint) 0.2s;
  }

  &.not-empty input[type='date'],
  input[type='date']:focus,
  &.not-empty input[type='date']::-webkit-datetime-edit-fields-wrapper,
  input[type='date']:focus::-webkit-datetime-edit-fields-wrapper {
    opacity: 1;
  }

  .clear-btn {
    display: none;
  }

  input:hover ~ .clear-btn,
  input:focus ~ .clear-btn,
  .clear-btn:hover {
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    padding: 0.5rem;
    will-change: transform;
    transform: translate3d(0, -50%, 0) scale3d(1, 1, 1);
    transition: transform var(--easeOutQuint) 0.5s;
    opacity: 0.2;

    &:hover {
      transform: translate3d(0, -50%, 0) scale3d(1.3, 1.3, 1);
    }
  }

  // curently handles search icon
  .left-icon {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    pointer-events: none;
  }

  .left-icon ~ input {
    padding: 1.25rem 2.5rem 0.5rem 3rem;
  }

  .left-icon ~ .label {
    left: 3rem;
  }

  &--disabled {
    pointer-events: none;

    .left-icon {
      opacity: 0.6;
    }
  }
}

.tip {
  font-size: 0.75rem;
  left: 0;
  top: calc(100% + 0.25rem);
}

.text-field.error {
  box-shadow: 0 0 0 1px var(--color-red-50);

  .tip {
    color: var(--color-red-50);
  }
}

.text-field.success {
  box-shadow: 0 0 0 1px var(--color-green-50);

  input {
    background-color: var(--color-green-20);
  }

  .tip {
    color: var(--color-green-50);
  }
}

// workaround firefox date picker clear button
input[type='date'] ~ .clear-btn {
  display: none !important;
}
.mz-date-clear-hide {
  display: none;
}
@supports (-moz-appearance: none) {
  input:hover ~ .clear-btn,
  input:focus ~ .clear-btn,
  .text-field.focus .clear-btn,
  .clear-btn:hover {
    display: block !important;
  }
  input[type='date'] ~ .mz-date-clear-hide {
    display: block;
    position: absolute;
    right: 2.5rem;
    bottom: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.5rem;
    background-color: var(--color-gray-10);
    z-index: 1;

    &:hover {
      cursor: default;
    }
  }
}

// TODO: refactor tooltip without before after
[data-tooltip] {
  position: relative;
  cursor: pointer;
}
[data-tooltip]:before,
[data-tooltip]:after {
  font-size: 0.75rem;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  display: none;
  opacity: 0;
}
[data-tooltip]:before {
  content: '';
  border: 0.25rem solid transparent;
  z-index: 100;
}
[data-tooltip]:after {
  content: attr(data-tooltip);
  text-align: center;
  width: 96%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  padding: 0.75rem 0.75rem;
  border-radius: 0.25rem;
  background: var(--color-primary);
  color: var(--color-on-primary);
  z-index: 99;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  opacity: 1;
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow='top']::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: var(--color-primary);
}
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow='top']::after {
  bottom: calc(100% + 5px);
}
[data-tooltip]:not([data-flow])::before,
[tooltip]:not([data-flow])::after,
[data-tooltip][data-flow='top']::before,
[data-tooltip][data-flow='top']::after {
  left: 50%;
  transform: translate(-50%, -4px);
}
[data-tooltip][data-flow='bottom']::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: var(--color-primary);
}
[data-tooltip][data-flow='bottom']::after {
  top: calc(100% + 5px);
}
[data-tooltip][data-flow='bottom']::before,
[data-tooltip][data-flow='bottom']::after {
  left: 50%;
  transform: translate(-50%, 1px);
}
[data-tooltip][data-flow='left']::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: var(--color-primary);
  left: calc(0em - 5px);
  transform: translate(-8px, -50%);
}
[data-tooltip][data-flow='left']::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-8px, -50%);
}
[data-tooltip][data-flow='right']::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: var(--color-primary);
  right: calc(0em - 5px);
  transform: translate(8px, -50%);
}
[data-tooltip][data-flow='right']::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(8px, -50%);
}
.no-tooltip:hover::after,
.no-tooltip:hover::before {
  opacity: 0;
  pointer-events: none;
}
</style>
