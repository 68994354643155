<script setup lang="ts">
import type { PropType } from 'vue'
import { inject, defineAsyncComponent, watch, computed, onBeforeMount, reactive, ref } from 'vue'

import type { AxiosError } from 'axios'
import type { Icon } from '@keyo/ui'
import { DialogBtn, Pin } from '@keyo/ui'
import { usePersonalStore, type PhoneSetBody } from '../pinia'

import useInputSize from '@/composables/useInputSize'
import useFormHelpers from '@/composables/useFormHelpers'

import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'

import BtnResend from '@/components/BtnResend/BtnResend.vue'
import { i18n } from '@keyo/core/i18n'
import { mfaCode, phone, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import accountApi from '@/modules/account/api'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

const props = defineProps({
  clientId: {
    type: String,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
  kind: {
    type: String as PropType<'modalCard' | 'authCard'>,
    default: () => 'modalCard',
  },
  heading: {
    type: String,
  },
  description: {
    type: String,
  },
  icon: {
    type: String as PropType<typeof Icon.props.name>,
  },
  primaryButton: {
    type: String,
  },
  secondaryButton: {
    type: String,
  },
})

const emit = defineEmits(['finished', 'canceled'])

const captcha = inject(captchaInjectionKey) as Captcha

const headingText = computed(() => props.heading ?? i18n.global.t('common.addPhoneNumber'))

const primaryButtonText = computed(() => props.primaryButton ?? i18n.global.t('buttons.continue'))
const secondaryButtonText = computed(() => props.secondaryButton ?? i18n.global.t('buttons.cancel'))

const personalStore = usePersonalStore()
const { setPhone } = personalStore

const { handleResponseException } = useFormHelpers()

const isSubmitting = ref(false)

const size = useInputSize()

const component = computed(() => {
  switch (props.kind) {
    case 'authCard':
      return defineAsyncComponent(() => import('@/modules/auth/components/AuthCard.vue'))
    case 'modalCard':
    default:
      return defineAsyncComponent(() => import('@/components/modals/components/ModalCard.vue'))
  }
})

const form = reactive<PhoneSetBody>({
  phone: '',
  code: [],
  client_id: '',
})

type Errors = { [key: string]: string } & Partial<typeof form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  code: [required(), mfaCode()],
  phone: [required(), phone()],
  client_id: [required()],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

const resendPhoneCode = async () => {
  try {
    await captcha.execute()
    const { data } = await accountApi.mfaCodeRequest({
      method: 'phone',
      phone: form.phone,
      action: 'confirm_phone',
      captcha_token: captcha.token.value,
    })

    form.client_id = data.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

const submit = async () => {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await v$.value.$validate()
    if (v$.value.$error) {
      isSubmitting.value = false
      return
    }

    await setPhone(form)
    emit('finished')
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onBeforeMount(() => {
  form.client_id = props.clientId as string
  form.phone = props.phone as string
})

// Clear validation results when code changes
watch(
  () => form.code,
  () => {
    v$.value.$reset()
    v$.value.$clearExternalResults()
  },
)
</script>

<template>
  <component
    :is="component"
    tag="form"
    size="m"
    :icon="icon"
    icon-color="lavender"
    :heading="headingText"
    :description="
      description ?? $t('modules.account.common.securityCodeSentToPhone', { phone: form.phone })
    "
    @submit.prevent="submit"
  >
    <Pin
      :size="size"
      :disabled="isSubmitting"
      class="pin"
      @input="form.code = $event"
      @complete="submit"
    />
    <NonFieldErrors :errors="v$.code.$errors?.[0]?.$message" />

    <BtnResend @click="resendPhoneCode()" />

    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <template #buttons>
      <DialogBtn
        class="cancel-button"
        kind="secondary"
        :disabled="isSubmitting"
        @click="$emit('canceled')"
      >
        {{ secondaryButtonText }}
      </DialogBtn>
      <DialogBtn type="submit" :disabled="!form.code" :loading="isSubmitting">
        {{ primaryButtonText }}
      </DialogBtn>
    </template>
  </component>
</template>

<style scoped lang="scss">
.pin {
  justify-self: center;
}
</style>
