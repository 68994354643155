import { computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'

// TODO: for the Btn component L size styles applied by default - undefined, extract styles to btn--l class
// eslint-disable-next-line prettier/prettier
export default function useInputSize<const M = undefined, const D = 'm'>(mobile?: M, desktop?: D) {
  const d = desktop ?? 'm'
  const isDesktopScreen = useMediaQuery('(min-width: 769px)') // TODO: single source for scss media variables and js
  return computed(() => (isDesktopScreen.value ? d : mobile))
}
