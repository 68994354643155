<script lang="ts">
import { Btn } from '@keyo/ui'
import useModal from '@/composables/useModal'
export default {
  name: 'InviteSent',
  components: { Btn },
  setup() {
    const modal = useModal()
    return { modal }
  },
}
</script>

<template>
  <div class="invite__container">
    <div class="invite__img--wrapper">
      <div class="invite__img--inner">
        <img src="@/assets/images/admin/invite-sent.svg" alt="Letter" class="invite__img" />
      </div>
    </div>
    <div class="invite__info">
      <h1 class="invite__title">{{ $t('components.modals.inviteSent.title') }}</h1>
      <p class="invite__text">{{ $t('components.modals.inviteSent.description') }}</p>
    </div>
    <div class="invite__buttons">
      <Btn @click="modal.show('invite-form')">
        {{ $t('components.modals.inviteSent.buttons.inviteAnotherUser') }}
      </Btn>
      <Btn kind="minimal" @click="modal.hide()">
        {{ $t('components.modals.inviteSent.buttons.done') }}
      </Btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.invite__container {
  width: 100%;
  max-width: 630px;
}
.invite__img--wrapper {
  display: grid;
  place-items: center;
  width: 106px;
  height: 106px;
  background: rgba(249, 249, 249, 0.6);
  border-radius: 6px;
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.1);
}
.invite__img--inner {
  display: grid;
  place-items: center;
  width: 90px;
  height: 90px;
  border-radius: 6px;
  background: #f9f9f9;
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.1);
}
.invite__info {
  margin: 40px 0 75px 0;
  width: 100%;
  max-width: 410px;
}
.invite__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 6px 0;
}
.invite__text {
  font-size: 16px;
  margin: 0;
}
.invite__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}
</style>
