/**
 * Utility functions to simplify using Zendesk Chat Web Widget. For docs on API see:
 * https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/
 * https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 */

import zendeskApi from './api/zendesk'

type LoginCallback = (arg: string) => void
type ParamsLogin = ['messenger', 'loginUser', (callback: LoginCallback) => void]
type ParamsLogout = ['messenger', 'logoutUser']
type ParamsSetZIndex = ['messenger:set', 'zIndex', number]
type Params = ParamsLogin | ParamsLogout | ParamsSetZIndex

const MOBILE_ZENDESK = 'mobileZendesk'
const storeJwt = (token: string) => localStorage.setItem(MOBILE_ZENDESK, token)
const getStoredJwt = () => localStorage.getItem(MOBILE_ZENDESK)
const removeStoredJwt = () => localStorage.removeItem(MOBILE_ZENDESK)

const callZendeskChatApi = (...params: Params) => {
  const [group, func, ...rest] = params
  try {
    window.zE?.(group, func, ...rest)
  } catch (e) {
    console.warn(e)
    console.warn('Zendesk Web Widget has not initialized')
  }
}

const login = (jwt: string) => {
  callZendeskChatApi('messenger', 'loginUser', (callback: LoginCallback) => {
    callback(jwt)
  })
}

const logout = () => {
  removeStoredJwt()
  callZendeskChatApi('messenger', 'logoutUser')
}

const fetchJwt = async (): Promise<string | null> => {
  try {
    const response = await zendeskApi.getJwt()
    const jwt = response.data.mobile_zendesk_token
    if (jwt) {
      storeJwt(jwt)
    } else {
      removeStoredJwt()
    }
    return jwt
  } catch (e) {
    removeStoredJwt()
    return null
  }
}

const getJwt = async (): Promise<string | null> => getStoredJwt() || fetchJwt()

const LOGIN_TIMEOUT = 1000

let loginTimeoutId: ReturnType<typeof setTimeout>

export default {
  setZIndex: (zIndex: number) => {
    callZendeskChatApi('messenger:set', 'zIndex', zIndex)
  },
  authorize: async () => {
    const jwt = await getJwt()

    if (jwt) {
      // login action needs to be async to work properly
      // otherwise if the chat window is open already and the page is refreshed, the user will not be logged in
      loginTimeoutId = setTimeout(() => login(jwt), LOGIN_TIMEOUT)
    }

    // there is no need to call logout here,
    // because the user will be logged out automatically if the jwt is invalid
  },
  logout: () => {
    // prevent login action from being called incorrectly
    clearTimeout(loginTimeoutId)
    logout()
  },
}
