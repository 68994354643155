<script>
import { states } from './model'
import { CheckCircle, Pass } from '../elements'
import { defineComponent } from 'vue'
import { Badge } from '@keyo/ui'

export default defineComponent({
  name: 'SelectField',

  components: {
    CheckCircle,
    Pass,
    Badge,
  },

  props: {
    id: { type: String },
    modelValue: {
      type: [String, Array, Number],
      required: false,
    },
    isDisabled: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    state: {
      type: String,
      validator: value => states.includes(value),
      default: '',
    },
    name: { type: String, default: '' },
    tip: { type: String, default: '' },
    label: { type: String, default: 'Label' },
    options: { type: [Array, Object], required: true },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      value: this.isMultiple ? [] : '',
      isOpen: false,
      selectedTitle: this.options?.find?.(
        // TODO: probably refactor to computed
        o => o.value === this.modelValue || o === this.modelValue,
      )?.title,
    }
  },

  computed: {
    model: {
      get() {
        return this.modelValue ?? this.value
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.value = value
      },
    },
    isNotEmpty() {
      return this.isMultiple ? !!this.model.length : !!this.model
    },
    isOptGroup() {
      return !Array.isArray(this.options)
    },
    selectedList() {
      return this.isMultiple ? this.model : []
    },
  },

  methods: {
    handleSelect(value, title) {
      if (this.isDisabled) return
      this.selectedTitle = title || value

      if (!this.isMultiple) {
        this.$el.blur()
      }
      if (!Array.isArray(this.model)) {
        this.value = value
        this.$emit('update:modelValue', value)
        return
      }
      const nextValue = this.model.slice()
      const i = this.model.indexOf(value)

      i === -1 ? nextValue.push(value) : nextValue.splice(i, 1)
      this.value = nextValue
      this.$emit('update:modelValue', nextValue)
    },
    handleFocus() {
      this.isOpen = true
    },
    handleFocusOut() {
      this.isOpen = false
    },
    handleChevronPress() {
      this.isOpen ? this.$el.blur() : this.$el.focus()
    },
    checkIfSelected(option) {
      const value = option.value ?? option
      return this.isMultiple ? this.model.includes(value) : this.model === value
    },
  },
})
</script>

<template>
  <div
    :id="id"
    ref="select"
    class="select"
    :class="[state, { 'select--disabled': isDisabled, 'is-open': isOpen }]"
    tabindex="0"
    @focus="handleFocus"
    @focusout="handleFocusOut"
  >
    <select
      v-if="isOptGroup"
      v-model="model"
      tabindex="-1"
      :disabled="isDisabled"
      :multiple="isMultiple"
      :name="name"
    >
      <optgroup v-for="(group, groupName) in options" :key="group" :label="groupName.toString()">
        <option
          v-for="option in group"
          :key="option.value || option"
          :value="option.value || option"
        >
          {{ option.title || option }}
        </option>
      </optgroup>
    </select>

    <select v-else v-model="model" :multiple="isMultiple" :disabled="isDisabled" :name="name">
      <option
        v-for="option in options"
        :key="option.value || option"
        :value="option.value || option"
      >
        {{ option.title || option }}
      </option>
    </select>

    <label
      class="label"
      :for="id"
      :class="{ 'label--active': isNotEmpty }"
      @click="$refs.select.focus()"
    >
      {{ label }}
    </label>

    <template v-if="isMultiple">
      <Badge v-for="v in selectedList" :key="v" class="select__badge" @click="handleSelect(v)">
        {{ v }}
      </Badge>
    </template>
    <span v-else class="select__value">{{ selectedTitle }}</span>

    <img
      class="chevron"
      src="@/assets/icons/chevron.svg"
      alt="chevron-toggle"
      @click="handleChevronPress"
      @mousedown.prevent=""
    />

    <transition name="options">
      <template v-if="isOpen">
        <ul v-if="isOptGroup" class="options">
          <li v-for="(group, groupName) in options" :key="groupName" class="option-group">
            <span class="option-group__label">{{ groupName }}</span>
            <ul>
              <Pass
                v-for="option in group"
                :key="option.value || option"
                :is-selected="checkIfSelected(option)"
                :title="option.title || option"
                :value="option.value || option"
              >
                <template #default="props">
                  <li
                    class="option"
                    :class="{ 'option--selected': props['is-selected'] }"
                    @click="handleSelect(props.value, props.title)"
                  >
                    <span class="option__title">{{ props.title }}</span>
                    <CheckCircle v-if="isMultiple" :is-checked="props['is-selected']" />
                  </li>
                </template>
              </Pass>
            </ul>
          </li>
        </ul>
        <ul v-else class="options">
          <Pass
            v-for="option in options"
            :key="option.value || option"
            :is-selected="checkIfSelected(option)"
            :title="option.title || option"
            :value="option.value || option"
          >
            <template #default="props">
              <li
                class="option"
                :class="{ 'option--selected': props['is-selected'] }"
                @click="handleSelect(props.value, props.title)"
              >
                <span class="option__title">{{ props.title }}</span>
                <CheckCircle v-if="isMultiple" :is-checked="props['is-selected']" />
              </li>
            </template>
          </Pass>
        </ul>
      </template>
    </transition>
    <span v-if="tip.length > 0" class="tip">{{ tip }}</span>
  </div>
</template>

<style scoped lang="scss">
.select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  height: 3rem;
  width: 100%;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem; // to keep space for error message
  padding: 1.375rem 0.9375rem 0.4375rem 0.9375rem;
  background-color: var(--color-grey-200);
  outline: none;

  select {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .label {
    cursor: pointer;
    transform-style: preserve-3d;
    position: absolute;
    z-index: 0;
    top: 50%;
    overflow: hidden;
    left: 1rem;
    opacity: 0.5;
    backface-visibility: hidden;
    will-change: transform, top;
    transform-origin: 0 50% 0;
    transform: translate3d(0, -50%, 0) scale3d(1, 1, 1);
    transition: all var(--easeOutQuint) 0.2s;

    -webkit-font-smoothing: antialiased;

    &--active {
      transform: translate3d(0, 0%, 0) scale3d(0.8, 0.8, 1);
      top: 0.0625rem;
    }
  }

  &__tag {
    margin: 6px 7px 6px 0;
  }

  .options {
    box-sizing: content-box;
    position: absolute;
    max-height: 200px;
    padding: 11px 15px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: var(--z-dropdown);
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-grey-200);
    // box-shadow: 0px 0px 0px 2px var(--color-gray-30);
    border-left: 1px solid var(--color-gray-30);
    border-bottom: 1px solid var(--color-gray-30);
    border-right: 1px solid var(--color-gray-30);

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    scrollbar-color: var(--color-primary);

    will-change: opacity, transform;

    &-enter-to,
    &-leave-from {
      transform: scale(1, 1);
      opacity: 1;
    }

    &-enter-active,
    &-leave-active {
      transition: all var(--easeOutQuint) 0.2s;
      transform-origin: top center;
    }

    &-enter-from,
    &-leave-to {
      transform: scale(1, 0);
      opacity: 0;
    }

    .option-group {
      &__label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        padding: 19px 0;
      }

      .option {
        padding: 10px 5px;
      }
    }

    .option {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;

      &:hover {
        background: var(--color-grey-300);
        cursor: pointer;
      }

      &__title {
        font-size: 16px;
        opacity: 0.7;
      }

      &--selected {
        span {
          opacity: 1;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      // background-color: var(--color-gray-40);
    }

    &::-webkit-scrollbar-track {
      height: 60px;
      width: 10px;
      border: 9px solid rgba(0, 0, 0, 0);

      background-clip: padding-box;

      border-radius: 2em;
      -webkit-border-radius: 2em;
      background-color: var(--color-gray-50);
    }

    // &::-webkit-scrollbar-thumb {
    //   border-radius: 2px;
    //   width: 4px;
    //   background-color: var(--color-primary);
    // }

    &::-webkit-scrollbar {
      width: 20px; // Total width including `border-width` of scrollbar thumb
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 60px;
      border: 9px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 2em;
      -webkit-border-radius: 2em;
      background-color: var(--color-primary);
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .chevron {
    position: absolute;
    right: 15px;
    padding: 10px;
    transform: translate3d(0, -50%, 0) rotate(0);
    top: 50%;
    transition: all 0.4s ease;
  }

  &.is-open,
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color-gray-30);
  }

  &.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .chevron {
      transform: translate3d(0, -50%, 0) rotate(180deg);
    }
  }

  &--disabled {
    pointer-events: none;

    .chevron {
      opacity: 0.6;
    }

    .select__value {
      color: var(--color-gray-80);
    }

    .select__badge {
      opacity: 0.6;
    }
  }
}

.tip {
  position: absolute; // to prevent fields jumping
  font-size: 0.75rem;
  left: 0;
  top: calc(100% + 0.25rem);
}

.select.error {
  box-shadow: 0 0 0 1px var(--color-red-50);
  .tip {
    color: var(--color-red-50);
  }
}

.select.success {
  box-shadow: 0 0 0 1px var(--color-green-50);
  .tip {
    color: var(--color-green-50);
  }
}
</style>
