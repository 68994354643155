<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import type { DropdownOption } from '@keyo/ui'
import { Avatar, Dropdown, Icon } from '@keyo/ui'

import useSidebar from './useSidebar'
import { useOrganizationsStore } from '@/store/organizations'
import { usePersonalStore } from '@/modules/account'

import { useI18n } from 'vue-i18n'
import { useModal } from '@/composables'

import photoFallbackSvg from './assets/photo-fallback.svg'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const modal = useModal()
const organizations = useOrganizationsStore()
const personal = usePersonalStore()

const { isSideBarCollapsed } = useSidebar()

const dropdownMenuClass = computed(() => ({
  'dropdown--collapsed': isSideBarCollapsed.value,
}))

type Organization = {
  name: string
  logo: string
  member: { role: string }
  id: number
}
type Option = DropdownOption & {
  logo: string
  initials: string
  role?: string
}

const options = computed<Option[]>(() =>
  organizations.spaces.map((o: Organization) => ({
    label: o.name,
    logo: o.logo,
    initials: o.name?.[0],
    role: t(`userRoles.${o.member.role.toLowerCase()}`),
    id: o.id,
  })),
)

const personalOption = computed<Option>(() => ({
  label: t('Personal account'),
  logo: personal.photo || photoFallbackSvg,
  initials: personal.initials,
}))

const selected = computed<Option>(
  () => options.value.find(o => o.id == route.params.id) ?? personalOption.value,
)
</script>

<template>
  <Dropdown :options="options" :clearable="false" class="dropdown" :class="dropdownMenuClass">
    <template #trigger-container>
      <Avatar :src="selected.logo" :initials="selected.initials" />
      <div class="info">
        <p class="text-label-s name">{{ selected.label }}</p>
        <p v-if="selected.role" class="text-label-xs role">{{ selected.role }}</p>
      </div>
    </template>
    <template #menu>
      <ul class="list">
        <li v-if="personal.canCreateOrganization" class="menu-item">
          <button
            class="new-org"
            @click="modal.show('CreateOrganization')"
            @keydown.enter="modal.show('CreateOrganization')"
          >
            <span class="avatar icon-holder">
              <Icon name="plus-4" />
            </span>
            <span class="text-label-xs">{{ $t('common.createNewOrganization') }}</span>
          </button>
        </li>
        <li v-for="o in options" :key="o.id" class="menu-item">
          <router-link
            :to="{ name: 'org', params: { id: o.id } }"
            class="item"
            @keydown.enter="router.push({ name: 'org', params: { id: o.id } })"
          >
            <Avatar :src="o.logo" :initials="o.initials" />
            <div class="info">
              <p class="text-label-s name">{{ o.label }}</p>
              <p class="text-label-xs role">{{ o.role }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </template>
    <template #custom-menu>
      <router-link
        :to="{ name: 'personal' }"
        class="personal"
        @keydown.enter="router.push({ name: 'personal' })"
      >
        <Avatar :src="personalOption.logo" :initials="personalOption.initials" />
        <p class="text-label-s">{{ personalOption.label }}</p>
      </router-link>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
.dropdown {
  border-radius: 0.75rem;
  height: auto;
  padding: var(--block-spacing);
  background: var(--clr-bg-primary);
  box-shadow:
    inset 0 0 0 1px var(--clr-border-default),
    0 2px 5px 5px var(--clr-bg-primary);
  border: 0;
  color: var(--clr-icon-subdued);
  z-index: 2;

  &.is-some-selected,
  &:hover {
    background: var(--clr-bg-primary);
    border: 0;
    cursor: pointer;
  }

  &:focus-within:not(.is-disabled) {
    border: 0;
    box-shadow: inset 0 0 0 1px var(--clr-border-focus);
  }
}

.new-org,
.item {
  width: 100%;
}

.new-org,
.item,
.personal {
  padding: 0.25rem;
  &:hover {
    background: var(--clr-bg-secondary);
  }

  &:focus-visible {
    outline: none;
    background: var(--clr-bg-secondary);
  }

  &:active {
    background: var(--clr-bg-tertiary);
  }
}

:deep(.dropdown__trigger),
.item,
.personal,
.new-org {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  .info {
    overflow: hidden;
  }

  .name {
    font-weight: 600;
    color: var(--clr-txt-default);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .role {
    color: var(--clr-txt-subtle);
  }
}

// arrow icon
:deep(.dropdown__actions) {
  width: 1.25rem;
  height: 1.25rem;
}

:deep(.avatar) {
  --clr-bg: var(--color-lavender-200);

  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  color: var(--color-lavender-700);
  border: 1px solid var(--clr-border-default);

  span {
    font: var(--text-label-s);
    font-weight: 600;
  }
}

.icon-holder {
  color: var(--clr-icon-subtle);
  background-color: var(--clr-bg-secondary);
  display: grid;
  place-items: center;
}

:deep(.dropdown__menu) {
  min-width: 14.5rem;
  max-height: 18.625rem;
  border: 1px solid var(--clr-border-default);
  padding: 0;
}

.list {
  padding: 0.25rem 0.25rem 0.5rem;

  // scrollbar styles
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track-piece {
    margin: 0.5rem 0;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey-300);
    background-clip: padding-box;
    border-radius: 6px;
    border: 3px solid transparent;

    &:hover {
      background-color: var(--color-grey-400);
    }

    &:active {
      background-color: var(--color-grey-500);
    }
  }

  .menu-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.75rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
.new-org {
  color: var(--clr-txt-default);
}

.personal {
  position: relative;
  border-radius: 0.75rem;
  margin: 0.5rem 0.25rem 0.25rem;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--clr-border-divider);
  }

  p {
    color: var(--clr-txt-default);
    font-weight: 600;
  }
}
@media screen and (min-width: $mobile) {
  .dropdown--collapsed {
    :deep(.dropdown__trigger .info),
    :deep(.dropdown__actions) {
      display: none;
    }
  }
}
</style>
