<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import { ref, watch } from 'vue'
import { _drawerState } from './useDrawer'

const emit = defineEmits<{
  update: [{ offsetWidth: number }]
}>()

const el = ref<ComponentPublicInstance>()
watch(el, () => {
  const $el = el.value?.$el as HTMLElement | undefined
  emit('update', {
    offsetWidth: $el?.offsetWidth ?? 0,
  })
})
</script>

<template>
  <div v-bind="$attrs" class="drawer-manager">
    <transition name="slide">
      <component
        :is="_drawerState.component"
        v-if="_drawerState"
        v-bind="_drawerState.props"
        ref="el"
        role="dialog"
      />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.drawer-manager {
  pointer-events: none;

  contain: paint;
  overflow: hidden;
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: 1rem;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.25s ease-in-out; // TODO: add transition customization via variables or something
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
