// Option to work only with single org. We should ALWAYS flush this store on organization switch
import { defineStore } from 'pinia'
import {
  createDeviceLocationPhoto,
  deleteDeviceLocationPhoto,
  getDeviceProfile,
  getOrganizationDevicesList,
  patchDeviceProfile,
  startEnroll,
} from '../api/organization'
import toast from '../libs/toast'

const prepareDevice = device => device

export const useDevicesStore = defineStore('devices', {
  state: () => ({
    items: new Map(),
    total: 0,
    isItemsFetched: false, // flag to check if items already was fetched to not block render.
  }),
  actions: {
    async fetchList(orgId) {
      try {
        const resp = await getOrganizationDevicesList(orgId)
        const devices = resp?.data?.results ?? []
        this.$patch(s => {
          devices.forEach(d => s.items.set(`${d.id}`, prepareDevice(d)))
          s.total = resp?.data?.count ?? 0
          s.isItemsFetched = true
        })
      } catch (e) {
        toast.show("Devices not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async fetchById(orgId, deviceId) {
      try {
        const resp = await getDeviceProfile(orgId, deviceId)
        const data = prepareDevice(resp.data)
        this.items.set(deviceId, data)
      } catch (e) {
        toast.show("Device not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async update(orgId, deviceId, body) {
      const resp = await patchDeviceProfile(orgId, deviceId, body)
      this.items.set(`${resp.data.id}`, resp.data)
    },

    async startEnroll(orgId, deviceId) {
      const resp = await startEnroll(orgId, deviceId)
      this.items.set(`${resp.data.id}`, resp.data)
    },

    async uploadPhoto(orgId, deviceId, file) {
      await createDeviceLocationPhoto(orgId, deviceId, file)
      await this.fetchById(orgId, deviceId)
    },

    async deletePhoto(orgId, deviceId, photoId) {
      await deleteDeviceLocationPhoto(orgId, deviceId, photoId)
      await this.fetchById(orgId, deviceId)
    },
  },
})
