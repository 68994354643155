import type { AxiosError } from 'axios'
import { apiV3, apiV4, contentTypeV2 } from '@/api/index.ts'
export type SignInMethod = 'phone' | 'email'
export type InvitationType = 'join_org' | 'invited_by_org'

/**
 * Ref: https://api-v3.rookie.keyo.co/api/v4/docs/#tag/auth/operation/send_code
 * Usage example
 * POST /send-code/
 * {
 *     "action": "confirm_email",
 *     "method": "email",
 *     "email": "user@example.com"
 * }
 *
 * POST /send-code/
 * {
 *     "action": "delete_profile",
 *     "method": "phone"
 * }
 *
 * POST /send-code/
 * {
 *     "action": "transfer_ownership",
 *     "method": "email",
 *     "organization_id": 136
 * }
 */

export type SendCodeActions =
  // Email and phone
  | 'delete_profile'
  | 'delete_biometric_data'
  | 'update_password'
  | 'transfer_ownership'
  | 'authorize_sign_in'
  | 'neat_sign_up'

  // Email
  | 'update_email'
  | 'confirm_email'

  // Phone
  | 'update_phone'
  | 'confirm_phone'

export type ExtraFields = 'phone' | 'email' | 'organization_id'

export interface SendCodeBody extends Partial<Record<ExtraFields, string | number>> {
  action: SendCodeActions
  method: SignInMethod
  captcha_token: string
}

export type SignInBody = {
  method: SignInMethod
  captcha_token: string
  password: string
  phone?: string
  email?: string
}

export interface ResetPassBody {
  hash: string
  password: string
}

export interface SetPassEmailBody {
  email?: string
}

export interface SignInMfaBody {
  method: string
  client_id: string
  code: string
}

export interface SignUpBaseBody {
  email?: string
  phone?: string
  first_name?: string
  last_name?: string
  password: string
}
export interface SignUpBody extends SignUpBaseBody {
  code: string
  client_id: string
}

export interface ActivateByInvitationBody extends SignUpBaseBody {
  hash: string
  organization: number
}

export const prepareBody = <T>(body: T) => {
  // TODO: Temporal solution while we completely remove the confirm password field form API
  const parsedBody = body as {
    password?: string
    password_check?: string
  }

  if (parsedBody.password) {
    parsedBody.password_check = parsedBody.password
  }

  return body as T
}

export const refreshToken = () => apiV3.post('auth/token/refresh/')
/**
 * Sign in with email/phone and password
 */
export const authLogin = async (payload: SignInBody) => {
  try {
    return await apiV3.post('auth/sign-in/', payload, {
      headers: contentTypeV2,
    })
  } catch (error) {
    // remap errors to actual form emailOrPhone field
    const { response } = error as AxiosError
    if (!response) {
      throw error
    }

    const { data } = response as {
      data: { email?: string; phone?: string; emailOrPhone: string }
    }

    if (data.email) {
      data.emailOrPhone = data.email
      delete data.email
    }
    if (data.phone) {
      data.emailOrPhone = data.phone
      delete data.phone
    }
    throw error
  }
}

export const authSignUpValidate = (data: SignUpBaseBody) => {
  return apiV3.post('auth/neat-sign-up/validate-data/', data)
}

export const activateUserByInvitation = (body: ActivateByInvitationBody) => {
  return apiV4.patch('auth/activate/', prepareBody<ActivateByInvitationBody>(body))
}

export const authSignUp = (body: SignUpBody) => {
  return apiV3.post('auth/neat-sign-up/', prepareBody<SignUpBody>(body), {
    headers: contentTypeV2,
  })
}

export const authResetPassEmail = (email: string) => {
  return apiV3.post('auth/password/send-restore-link/', { email })
}

export const authResetPassPhone = (phone: string, captcha_token: string) => {
  return apiV3.post('auth/password/send-restore-link-sms/', { phone, captcha_token })
}

export const authSetPassEmail = (body: SetPassEmailBody) => {
  return apiV3.post('auth/sign-up/', prepareBody<SetPassEmailBody>(body))
}

export const validateHashBeforeSignup = (hash: string) => {
  return apiV3.post('auth/sign-up/validate-hash/', {
    hash: hash,
    action: 'system_user_invitation',
  })
}

export const validateHashBeforeResetPassword = (hash: string) => {
  return apiV3.post('auth/sign-up/validate-hash/', {
    hash,
    action: 'system_user_restore',
  })
}

export const resendInvitation = (email: string) => {
  return apiV3.post('users/resend-user-invitation/', { email })
}

export const signUp = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...body } = data
  return apiV3.patch('users/activate/', prepareBody(body))
}

export const authResetPass = (body: ResetPassBody) => {
  return apiV3.post('auth/password/restore/', prepareBody(body))
}

export const authSetPass = (body: SetPassEmailBody) => {
  return apiV3.post('auth/password/set/', prepareBody(body))
}

export const authRegister = (email: string) => {
  return apiV3.post('auth/sign-up/validate-email/', { email })
}

// TODO: Complete with real endpoint - [DASH-1218 : Accept Keyo's Terms and Privacy Policy](https://linear.app/keyoid/issue/DASH-1218/accept-keyos-terms-and-privacy-policy)
export const authAcceptTerms = () => {
  return new Promise(res => setTimeout(res, 1000))
}

export const authSignInMfa = (body: SignInMfaBody) => {
  return apiV3.post('auth/sign-in-mfa/', prepareBody(body), {
    headers: contentTypeV2,
  })
}

/**
 * Used to re-send activation link that is sent during Pilot sign up at kiosk(Keyo OS)
 */
export const authSendSetPasswordLink = (email: string) => {
  return apiV3.post('auth/password/send-set-link/', { email })
}
