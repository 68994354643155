export default [
  {
    path: '/organizations/:id/integrations',
    meta: {
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '',
        name: 'org.integrations',
        component: () => import('@/modules/integrations/pages/Integrations.vue'),
        meta: {
          title: 'Integrations',
          breadcrumbs: [{ label: 'Integrations', route: 'org.integrations' }],
        },
      },
      {
        path: 'pawapay',
        name: 'org.integrations.pawapay',
        component: () => import('@/modules/integrations/pages/PawaPay.vue'),
        meta: {
          title: 'pawaPay',
          breadcrumbs: [
            { label: 'Integrations', route: 'org.integrations' },
            { label: 'pawaPay', route: 'org.integrations.pawapay' },
          ],
        },
      },
    ],
  },
]
