import { apiV3 } from '@/api'

export default {
  fetch(orgId) {
    return apiV3.get(`organizations/${orgId}/creds/`)
  },
  rotate(orgId) {
    return apiV3.post(`organizations/${orgId}/creds/rotate/`)
  },
}
