/* basic form helpers until we have a better solution with a core package */
import type { AxiosResponse } from 'axios'
import toast from '@/libs/toast'
import { i18nUtils } from '@keyo/core/i18n'
import type { FieldErrors } from '@keyo/core/validations'
import { fieldErrorProcessor } from '@keyo/core/validations'

interface UseFormHelpersOptions {
  useToast: boolean
  unexpectedMsg: typeof i18nUtils.errorSomethingBroken
  tooManyMsg: typeof i18nUtils.errorTooMany
}

const SERVER_STATUS_ERROR = 500
const T00_MANY_REQUESTS_ERROR = 429

export default function useFormHelpers() {
  const handleToastUse = (parsedErrors: FieldErrors) => {
    Object.keys(parsedErrors).forEach(errorKey => {
      toast.show(parsedErrors?.[errorKey], 'error')
    })
  }

  const handleResponseException = <E>(
    response: AxiosResponse | undefined,
    errors: E | FieldErrors,
    options?: Partial<UseFormHelpersOptions>,
  ) => {
    const defaultOptions: UseFormHelpersOptions = {
      useToast: false,
      unexpectedMsg: i18nUtils.errorSomethingBroken,
      tooManyMsg: i18nUtils.errorTooMany,
    }
    const originalErrors = errors
    const { useToast, unexpectedMsg, tooManyMsg }: UseFormHelpersOptions = {
      ...defaultOptions,
      ...options,
    }

    if (!response) {
      toast.show(() => unexpectedMsg(), 'error')
      return
    }

    if (response.status >= SERVER_STATUS_ERROR) {
      toast.show(() => unexpectedMsg(), 'error')
      return
    }

    switch (response.status) {
      case T00_MANY_REQUESTS_ERROR:
        toast.show(() => tooManyMsg(response.headers?.['retry-after'] ?? 0), 'error')
        break
      default: {
        if (!response.data) {
          return
        }

        const parsedErrors = originalErrors as FieldErrors
        fieldErrorProcessor.handleFieldsErrors(response.data, parsedErrors)

        if (useToast) {
          handleToastUse(parsedErrors)
        }
        break
      }
    }
  }

  return {
    handleResponseException,
  }
}
