<template>
  <section class="table-box"><slot /></section>
</template>
<style scoped>
.table-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.875rem 0;
  padding: 1rem;
  border-radius: var(--table-radius);
  background: var(--clr-bg-primary);
}
</style>
