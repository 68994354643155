import { defineStore } from 'pinia'
import webhooks from '../api'

const handleWebhookItem = item => ({
  createdAt: (new Date(item.creation_date)).toLocaleDateString(),
  isActive: item.status.name === 'ACTIVE',
  isSecretSet: item.is_secret_token_existing,
  ...item,
})

export default defineStore('webhooks', {
  state: () => ({
    items: [],
    byId: {},
    eventTypes: [],
    isItemsFetched: false, // flag to check if items already was fetched to not block render.
  }),
  actions: {
    async create(orgId, body) {
      try {
        await webhooks.create(orgId, body)
        this.fetchList(orgId)
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },
    async fetchList(orgId) {
      try {
        const resp = await webhooks.list(orgId)
        this.$patch(state => {
          state.items = resp.data.results.map(handleWebhookItem)
          state.items.forEach(itm => {
            state.byId[itm.id] = itm
          })
          state.isItemsFetched = true
        })
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },
    async disable(orgId, id) {
      try {
        const { data } = await webhooks.update(orgId, id, { status: 0 })
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },

    async update(orgId, id, webhook) {
      try {
        const { data } = await webhooks.update(orgId, id, webhook)
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },

    async enable(orgId, id) {
      try {
        const { data } = await webhooks.update(orgId, id, { status: 1 })
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw (e)
      }

    },

    async delete(orgId, id) {
      try {
        await webhooks.delete(orgId, id)
        this.$patch(state => {
          state.items.splice(state.byId[id].itemIndex, 1)
          delete state.byId[id]
        })
      } catch (e) {
        console.error(e)
        throw (e)
      }
    },
  },
})
