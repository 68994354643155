<script>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Thumbs } from 'swiper/modules'

import { useModal } from '@/composables'

import { Btn } from '@keyo/ui'

import 'swiper/css'
import 'swiper/css/thumbs'

const thumbsBreakpoints = {
  // when window width is >= 320px
  1750: { slidesPerView: 8 },
  1500: { slidesPerView: 7 },
  1250: { slidesPerView: 6 },
  1000: { slidesPerView: 5 },
  800: { slidesPerView: 4 },
  600: { slidesPerView: 3 },
}

export default {
  components: { Swiper, SwiperSlide, Btn },
  props: {
    images: { type: Array, default: () => [] },
    initialIndex: { type: Number, default: 0 },
  },
  setup() {
    const thumbs = ref()
    const swiper = ref()
    return {
      modules: [Thumbs],
      setThumbs(s) {
        thumbs.value = s
      },
      setSwiper(s) {
        swiper.value = s
      },
      thumbs,
      swiper,
      modal: useModal(),
      thumbsBreakpoints,
    }
  },
}
</script>

<template>
  <div class="gallery__container">
    <div class="main-swiper-holder">
      <swiper
        :space-between="25"
        :modules="modules"
        :thumbs="{ swiper: thumbs }"
        :resistance="false"
        :initial-slide="initialIndex"
        navigation
        rewind
        grab-cursor
        @swiper="setSwiper"
      >
        <template #container-end>
          <Btn icon="arrow-left" size="s" class="nav nav--prev" @click="swiper.slidePrev()" />
          <Btn icon="arrow-right" size="s" class="nav nav--next" @click="swiper.slideNext()" />
        </template>
        <swiper-slide v-for="(img, i) in images" :key="i">
          <img :src="img" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="thumbs-swiper-holder">
      <swiper
        class="thumbs"
        :slides-per-view="8"
        :space-between="15"
        :modules="modules"
        :breakpoints="thumbsBreakpoints"
        grab-cursor
        @swiper="setThumbs"
      >
        <swiper-slide v-for="(img, i) in images" :key="i">
          <img :src="img" />
        </swiper-slide>
      </swiper>
    </div>
    <Btn kind="minimal" size="m" class="close" icon="close-remove" @click="modal.hide()" />
  </div>
</template>

<style lang="scss" scoped>
.gallery__container {
  position: relative;
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main-swiper-holder {
    height: 80%;
  }

  .main-swiper-holder,
  .thumbs-swiper-holder {
    width: 100%;
  }

  .thumbs-swiper-holder {
    height: 18%;

    img {
      object-fit: cover;
    }
  }
  .nav {
    z-index: 1;
    position: absolute;
    top: 50%;
    user-select: none;
  }
  .nav--prev {
    left: 4px;
    img {
      transform: rotate(-90deg);
    }
  }
  .nav--next {
    right: 4px;
    img {
      transform: rotate(90deg);
    }
  }

  .close {
    z-index: 1;
    position: absolute;
    top: 40px;
    right: 40px;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  :deep(.swiper) {
    height: 100%;
  }

  :deep(.swiper-slide) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
