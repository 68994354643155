import { readonly, watchEffect } from 'vue'
import { useBreakpoints } from '@/composables/useBreakpoints'
import { useStorage } from '@vueuse/core'

const isSideBarCollapsed = useStorage<boolean>('isSideBarCollapsed', false)
const isSideBarSetByUser = useStorage<boolean>('isSideBarSetByUser', false)

export default function useSidebar() {
  function toggleSidebar() {
    isSideBarCollapsed.value = !isSideBarCollapsed.value
    isSideBarSetByUser.value = true
  }

  function setIsSidebarCollapsed(collapse: boolean) {
    isSideBarCollapsed.value = collapse
  }

  function setDefaultSidebarState() {
    const { isMobile, isTablet, isDesktop, isLargeDesktop } = useBreakpoints()

    if (isMobile.value || isTablet.value) setIsSidebarCollapsed(true)
    else if (isDesktop.value || isLargeDesktop.value) setIsSidebarCollapsed(false)
  }

  watchEffect(() => {
    if (!isSideBarSetByUser.value) setDefaultSidebarState()
  })

  return {
    isSideBarCollapsed: readonly(isSideBarCollapsed),
    setIsSidebarCollapsed,
    toggleSidebar,
  }
}
