<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useScrollLock } from '@vueuse/core'
import MenuLink from './MenuLink.vue'
import useSidebar from './useSidebar'
import { MOBILE_HASH_TOGGLE } from '@/constants/sidebar'
import { Icon, PoweredByKeyo } from '@keyo/ui'
import SpacesDropdown from './SpacesDropdown.vue'
import { API_REFERENCE, KNOWLEDGE_HUB, POSTMAN } from '@/constants/urls'
import { useCurrentRouteEntities } from '@/composables'

const { isSideBarCollapsed, toggleSidebar } = useSidebar()
const { organization } = useCurrentRouteEntities()
const route = useRoute()
const isPersonalActive = computed(() => /^\/personal/.test(route.fullPath))
const bodyScrollLock = useScrollLock(document.body)

// currently available only 1 integration via payment provider and enabled internally per organization
const hasIntegrations = computed(() => !!organization.value.payment_provider_ids.length)

watchEffect(() => {
  bodyScrollLock.value = route.hash === MOBILE_HASH_TOGGLE
})

const sidebarClasses = computed(() => ({
  'sidebar--collapsed': isSideBarCollapsed.value,
  'sidebar--open': route.hash === MOBILE_HASH_TOGGLE,
}))
const overlayClasses = computed(() => ({
  'overlay--open': route.hash === MOBILE_HASH_TOGGLE,
}))

const isWalletEnabled = import.meta.env.APP_ENABLE_WALLET
</script>
<template>
  <div class="overlay" :class="overlayClasses" />
  <nav key="sidebar" class="sidebar" :class="sidebarClasses">
    <router-link :to="{ hash: '' }" class="close-btn">
      <Icon name="close-remove" class="icon" />
    </router-link>
    <div class="skeleton">
      <SpacesDropdown />
    </div>
    <ul class="main-menu">
      <template v-if="isPersonalActive">
        <li>
          <MenuLink
            :to="{ name: 'personal.overview' }"
            icon="dashboard"
            :label="$t('layouts.dashboard')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'personal.profile' }"
            icon="user-profile"
            :label="$t('common.profile')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'personal.biometrics' }"
            icon="palm"
            :label="$t('layouts.biometrics')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'personal.security' }"
            icon="shield-done-filled"
            :label="$t('common.loginAndSecurity')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'personal.invites' }"
            icon="email-document"
            :label="$t('layouts.invitations')"
          />
        </li>
        <li>
          <MenuLink
            v-if="isWalletEnabled"
            :to="{ name: 'personal.wallet' }"
            icon="wallet"
            :label="$t('layouts.wallet')"
          />
        </li>
      </template>

      <template v-else>
        <li>
          <MenuLink
            :to="{ name: 'org.overview' }"
            icon="dashboard"
            :label="$t('layouts.dashboard')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.members' }"
            icon="users-2-filled"
            :label="$t('common.memberManagement')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.devices' }"
            icon="size"
            :label="$t('layouts.deviceManagement')"
          />
        </li>
        <li v-if="hasIntegrations">
          <MenuLink
            :to="{ name: 'org.integrations' }"
            icon="integration"
            :label="$t('Integrations')"
          />
        </li>

        <li class="list-name text-heading-caps">
          <p>{{ $t('layouts.developers') }}</p>
          <div class="line" />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.dev-tools.overview' }"
            icon="chart"
            :label="$t('common.overview')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.dev-tools.webhooks' }"
            icon="webhook"
            :label="$t('layouts.webhookManagement')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.dev-tools.api-creds' }"
            icon="key-filled"
            :label="$t('API credentials')"
          />
        </li>
        <li>
          <MenuLink
            :to="{ name: 'org.dev-tools.emulator' }"
            icon="fingerprint"
            :label="$t('layouts.systemActivityEmulator')"
          />
        </li>
        <li>
          <MenuLink
            tag="a"
            :href="API_REFERENCE"
            target="_blank"
            icon="cloud-settings"
            :label="$t('layouts.apiReference')"
          />
        </li>
        <li>
          <MenuLink
            tag="a"
            :href="POSTMAN"
            target="_blank"
            icon="postman"
            :label="$t('layouts.postmanCollection')"
          />
        </li>
        <li class="list-name text-heading-caps">
          <p>{{ $t('layouts.preferences') }}</p>
          <div class="line" />
        </li>

        <li>
          <MenuLink
            :to="{ name: 'org.settings.profile' }"
            icon="settings"
            :label="$t('layouts.settings')"
          />
        </li>
        <li>
          <MenuLink
            tag="a"
            :href="KNOWLEDGE_HUB"
            icon="doc"
            :label="$t('layouts.knowledgeHub')"
            target="_blank"
          />
        </li>
      </template>

      <li>
        <MenuLink
          :to="{ name: 'zendesk.auth' }"
          icon="info-square"
          :label="$t('layouts.helpCenter')"
          target="_blank"
        />
      </li>
    </ul>
    <div class="footer">
      <PoweredByKeyo />
      <button class="toggle" :aria-label="$t('layouts.toggleSidebar')" @click="toggleSidebar">
        <Icon class="icon-toggle" name="menu-collapse" />
      </button>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 0;
  height: 100%;
  background: rgba(17, 17, 17, 0.75);
  z-index: 1;

  &--open {
    width: 100%;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  width: 0;
  background: var(--clr-bg-primary);
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 1rem;
  padding: 0.75rem 0 1rem;
  margin: 1rem;
  z-index: var(--z-sidebar);

  @media not (prefers-reduced-motion) {
    transition: all 0.2s;
  }

  &--open {
    width: var(--sidebar-width);
    opacity: 1;
    overflow: visible;
    padding: 0.75rem 0.5rem 1rem;
    border: 1px solid var(--clr-border-default);
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: -4rem;
    width: 3rem;
    height: 3rem;
    background: var(--clr-bg-primary);
    display: grid;
    place-content: center;
    color: var(--clr-icon-subtle);
    border-radius: 0.75rem;
    border: 1px solid var(--clr-border-default);
  }

  .skeleton {
    height: 3rem;
    border-radius: 0.75rem;
  }

  .main-menu {
    display: grid;
    position: relative;
    gap: 0.25rem;

    // scrollbar styles
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -0.5rem 0 -1rem;
    padding: 0.75rem 0.25rem 1rem 1rem;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar-track-piece {
      margin: 1rem 0;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey-300);
      background-clip: padding-box;
      border-radius: 4px;

      &:hover {
        background-color: var(--color-grey-400);
      }

      &:active {
        background-color: var(--color-grey-500);
      }
    }
  }

  .list-name {
    color: var(--clr-txt-subdued);
    height: 2rem;

    p {
      margin: 0.75rem 0.75rem 0.25rem;
      white-space: nowrap;
    }

    .line {
      display: none;
      width: 1.5rem;
      height: 1px;
      background-color: var(--clr-icon-subdued);
      margin-top: 1.25rem;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin: auto 0.5rem 0;
    gap: 0.25rem;
    box-shadow: 0 -2px 5px 5px var(--clr-bg-primary);
    z-index: 1;

    .toggle {
      margin-left: auto;
      border-radius: 0.5rem;
      border: 1px solid var(--clr-border-default);
      padding: 3px;
      color: var(--clr-icon-subtle);
      display: none;

      .icon-toggle {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  :deep(.powered-by) {
    height: 1.25rem;
    font: var(--text-label-xs);
  }
}

@media screen and (min-width: $mobile) {
  .overlay {
    display: none;
  }

  .sidebar {
    width: var(--sidebar-width);
    position: relative;
    height: auto;
    opacity: 1;
    box-shadow: var(--box-shadow);
    margin: 0.75rem;
    border: 0;
    overflow: visible;
    padding: 0.75rem 0.5rem 1rem;

    .close-btn {
      display: none;
    }

    .main-menu {
      padding-right: 0.25rem;
    }

    .footer {
      .toggle {
        display: grid;
      }
    }
  }

  .sidebar--collapsed {
    width: var(--sidebar-width-collapsed);

    li {
      justify-self: center;
    }

    .main-menu {
      padding: 0.75rem 0 1rem 1.25rem;
      justify-content: start;

      .list-name {
        p {
          display: none;
        }

        .line {
          display: block;
        }
      }
    }

    .footer {
      flex-direction: column;
      gap: 0.75rem;
      align-items: flex-start;
      margin: auto 0.75rem 0;

      .toggle {
        transform: rotate(180deg);
        margin: 0;
      }
    }

    :deep(.powered-by) {
      align-self: center;

      .label,
      .logo-text {
        display: none;
      }
    }
  }
}
</style>
