import { reactive } from 'vue'
import type { Stringable } from '@keyo/core'
const types = ['success', 'error', 'neutral'] as const
type ToastType = (typeof types)[number]
export type Item = {
  _id: string
  msg: Stringable
  type: ToastType
  _timeout: ReturnType<typeof setTimeout>
}

export const items = reactive<Item[]>([])

const genId = () => Date.now().toString(32) + Math.floor(Math.random() * 10000).toString(32)

export const closeById = (id: string) => {
  const i = items.findIndex(itm => itm._id === id)
  if (i >= 0) {
    if (items[i]._timeout) clearTimeout(items[i]._timeout)
    items.splice(i, 1)
  }
}

export const show = (msg: Stringable, type: ToastType = 'neutral') => {
  if (!['success', 'error', 'neutral'].includes(type)) {
    console.warn('Toast type can be one of "success", "error", "neutral"')
    type = 'neutral'
  }

  const _id = genId()
  const _timeout = setTimeout(() => closeById(_id), 5000)
  items.push({
    _id,
    _timeout,
    type,
    msg: typeof msg === 'function' ? { toString: () => msg() } : msg,
  })
}
