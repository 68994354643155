// Option to work only with single org. We should ALWAYS flush this store on organization switch
import { defineStore } from 'pinia'
import toast from '@/libs/toast'
import {
  bulkDeleteMembership,
  getMembershipById,
  getMemberships,
  updateMembership,
} from '@/api/organization'
import * as invites from '@/modules/members/api/invites'
import { prepareUser } from '@/api/utils'
import { DEFAULT_LIMIT } from '@/constants/pagination'

// usersById {[id]: user}
// list: []

export const useUsersStore = defineStore('users', {
  state: () => ({
    items: new Map(),
    total: 0,
    isItemsFetched: false, // flag to check if items already was fetched to not block render.
  }),
  actions: {
    async invite(orgId, body) {
      await invites.invite(orgId, body)
      await this.fetchList(orgId)
    },
    async fetchList(id, search = '', page = 1, limit = DEFAULT_LIMIT) {
      try {
        const resp = await getMemberships(id, {
          offset: limit * (page - 1),
          limit,
          search,
        })
        const members = resp?.data?.results ?? []

        this.$patch(s => {
          const nextItems = new Map()
          members.forEach(m => nextItems.set(`${m.member_id}`, prepareUser(m)))
          s.items = nextItems
          s.total = resp.data.count
          s.isItemsFetched = true
        })
      } catch (e) {
        toast.show("Users not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async fetchById(orgId, memberId) {
      try {
        const userAssignTo = this.items.get(memberId)
        const resp = await getMembershipById(orgId, memberId)
        const data = prepareUser(resp.data)
        userAssignTo ? Object.assign(userAssignTo, data) : this.items.set(memberId, data)
      } catch (e) {
        toast.show("User not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async pauseMembership(orgId, memberId) {
      const resp = await updateMembership(orgId, memberId, { status: 2 })
      this.items.set(`${memberId}`, prepareUser(resp.data))
    },

    async renewMembership(orgId, memberId) {
      const resp = await updateMembership(orgId, memberId, { status: 1 })
      this.items.set(`${memberId}`, prepareUser(resp.data))
    },

    async remove(orgId, memberIds) {
      const resp = await bulkDeleteMembership(orgId, memberIds)
      if (resp.status === 204) {
        this.$patch(s => {
          memberIds.forEach(id => s.items.delete(`${id}`))
          s.total -= memberIds.length
        })
      }
    },
  },
})
