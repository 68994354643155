import type { AvailableLocales } from '@keyo/core/i18n'

export function censorString(str: string) {
  const censorLength = Math.ceil(str.length / 2)
  return `${'*'.repeat(censorLength)}${str.slice(censorLength)}`
}

export function censorEmail(email: string) {
  const [user, domain] = email.split('@')
  return `${censorString(user)}@${censorString(domain)}`
}

export const toLocaleString = (date: string | null, locale: AvailableLocales) =>
  date
    ? new Date(date).toLocaleDateString(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    : undefined
