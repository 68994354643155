import { validate } from '@/utils/validations'

import { computed, isReactive, reactive, watch } from 'vue'
import _get from 'lodash/get'

export const createFormValidation =
  validator =>
  (validations, defaults = {}) => {
    const errors = reactive({})
    const form = isReactive(defaults) ? defaults : reactive(defaults)
    const isFormValid = computed(() => !Object.values(errors).find(e => !!e))

    const validateForm = () => {
      Object.keys(validations).forEach(field => {
        const value = _get(form, field)
        errors[field] = validator(validations[field], value, form)
      })
      return isFormValid.value
    }

    const watchAllForm = (field, unwatch) =>
      watch(
        () => form,
        () => {
          delete errors[field]
          unwatch()
        },
        { deep: true },
      )
    const watchField = (field, unwatch) =>
      watch(
        () => _get(form, field),
        () => {
          delete errors[field]
          unwatch()
        },
      )

    watch(
      () => errors,
      value => {
        Object.keys(value).forEach(field => {
          const unwatch =
            field === 'non_field_errors'
              ? watchAllForm(field, () => unwatch())
              : watchField(field, () => unwatch())
        })
      },
      { deep: true },
    )

    return {
      form,
      errors,
      isFormValid,
      validateForm,
    }
  }

export default createFormValidation(validate)
