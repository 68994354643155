import { useRoute } from 'vue-router'
import { useOrganizationsStore } from '../store/organizations'
import { useUsersStore } from '../store/users'
import { useDevicesStore } from '../store/devices'
import { computed } from 'vue'

// basic hierarchy power
const roles = {
  Owner: 2,
  Admin: 1,
  User: 0,
}

export default function () {
  const route = useRoute()
  const users = useUsersStore()
  const organizations = useOrganizationsStore()
  const devices = useDevicesStore()

  const member = computed(() => users.items.get(route.params.memberId))
  const organization = computed(() => organizations.items.get(route.params.id))
  const device = computed(() => devices.items.get(route.params.deviceId))

  const isAuthnUserOwner = computed(() => {
    if (!organization.value) return false
    return Number(organization.value.owner.member_id) === Number(organization.value.member.id)
  })

  /**
   * Checks permission by role hierarchy
   * @param {Object} [m] - organization member. default to current route member
   * @returns {Boolean}
   */
  const canEditMember = (m = member.value) => {
    const loggedInMember = organization.value?.member
    if (!m || !loggedInMember?.role || !roles[loggedInMember.role]) return false
    return roles[loggedInMember.role] >= roles[m.role] && loggedInMember.id !== m.id
  }

  return { organization, member, device, canEditMember, isAuthnUserOwner }
}
