<script>
import toast from '@/libs/toast'

import TextField from '@/components/inputs/TextField'
import { Btn } from '@keyo/ui'
import IconBox from '@/components/IconBox'

import { mapActions } from 'pinia'
import { useWebhooksStore } from '../pinia'
import { useModal, useFormValidation } from '@/composables'
import webhooksApi from '../api/webhooks'
import useFormHelpers from '@/composables/useFormHelpers'

export default {
  components: { Btn, TextField, IconBox },
  props: {
    webhook: Object,
  },
  setup(props) {
    const { form, errors, isFormValid, validateForm } = useFormValidation({
      url: ['required', 'url'],
    })
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { secret_token, url } = props.webhook
    Object.assign(form, { secret_token, url })

    const { handleResponseException } = useFormHelpers()

    return {
      form,
      errors,
      isFormValid,
      validateForm,
      handleResponseException,
      modal: useModal(),
      toast: toast.show,
    }
  },

  data() {
    return {
      isSubmitting: false,
    }
  },
  created() {
    this.retrieveSecret()
  },
  methods: {
    ...mapActions(useWebhooksStore, ['update']),
    async retrieveSecret() {
      try {
        const resp = await webhooksApi.getSecret(this.$route.params.id, this.webhook.id)
        this.form.secret_token = resp.data.secret_token
      } catch (error) {
        toast.show(() => this.$t('modules.webhooks.retrieveSecretError'), 'error')
      }
    },
    async submitForm() {
      if (this.isSubmitting) return
      this.isSubmitting = true
      if (!this.validateForm()) {
        this.isSubmitting = false
        return
      }
      try {
        await this.update(this.$route.params.id, this.webhook.id, this.form)
        this.modal.hide()
      } catch (e) {
        console.error(e)
        this.handleResponseException(e.response, this.errors)
        this.isSubmitting = false
      }
    },
  },
}
</script>

<template>
  <form class="webhook-create__container" @submit.prevent="submitForm">
    <IconBox>
      <img src="../assets/icons/webhook-edit.svg" :alt="$t('modules.webhooks.penIcon')" />
    </IconBox>
    <h1 class="webhook-create__title">{{ $t('modules.webhooks.webhookEdit') }}</h1>
    <TextField
      id="url"
      v-model="form.url"
      :state="errors.url ? 'error' : ''"
      :tip="errors.url"
      :label="$t('modules.webhooks.endpointURL')"
    />
    <TextField
      id="secret"
      v-model="form.secret_token"
      type="password"
      :state="errors.secret_token ? 'error' : ''"
      :tip="errors.secret_token"
      :label="$t('modules.webhooks.secret')"
    />
    <div class="webhook-create__buttons">
      <Btn type="submit" :is-disabled="!isFormValid" :is-processing="isSubmitting">
        {{ $t('modules.webhooks.saveEndpoint') }}
      </Btn>
      <Btn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.webhook-create__container {
  width: 100%;
  max-width: 620px;
}
.webhook-create__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 20px 0;
}
.webhook-create__buttons {
  display: flex;
  gap: 1rem;
}
</style>
