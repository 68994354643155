export const INVITE_STATUS = {
  SENT: 0,
  ACCEPTED: 1,
  CANCELLED: 2,
} as const
export type INVITE_STATUS = (typeof INVITE_STATUS)[keyof typeof INVITE_STATUS]
export type INVITE_STATUS_NAME = keyof typeof INVITE_STATUS

export const INVITE_ACTIONS = {
  ACCEPT: INVITE_STATUS.ACCEPTED,
  DECLINE: INVITE_STATUS.CANCELLED,
} as const
export type INVITE_ACTIONS = (typeof INVITE_ACTIONS)[keyof typeof INVITE_ACTIONS]
