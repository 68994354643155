import { watchDebounced } from '@vueuse/core'
import { me } from '@keyo/core'
import type { Ref } from 'vue'
import { ref } from 'vue'

const MIN_PASSWORD_LENGTH = 3
let controller: AbortController | null = null

export default function usePasswordValidation(passwordRef: Ref<string>) {
  const strength = ref()
  watchDebounced(
    passwordRef,
    async (pass: string) => {
      try {
        if (pass.length <= MIN_PASSWORD_LENGTH) {
          strength.value = 0
          return
        }

        if (controller) {
          controller.abort()
        }

        controller = new AbortController()

        const {
          data: { complexity },
        } = await me.validatePassword(pass, controller.signal)
        strength.value = complexity
      } catch (error) {
        console.error(error)
      }
    },
    { debounce: 200, maxWait: 1000, immediate: true },
  )

  return {
    strength,
  }
}
