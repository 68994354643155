<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomCheckbox',
  props: {
    isChecked: { type: Boolean, default: false },
  },
})
</script>

<template>
  <div class="check-circle" :class="{ 'check-circle--checked': isChecked }">
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L3.5 7L1 4.27273"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.check-circle {
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--color-gray-70);
  transition: background var(--easeOutQuint) 0.5s;

  svg {
    transform: scale(0);
    transition: transform var(--easeOutQuint) 0.5s;
  }

}

.check-circle--checked,
input:checked + .check-circle {
  background: var(--color-primary);

  svg {
    transform: scale(1);
  }
}

input:focus + .check-circle {
  box-shadow: var(--shadow-focus-highlight)
}

input:disabled + .check-circle {
  opacity: 0.3
}


</style>