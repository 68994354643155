import { readonly } from 'vue'
import { useBreakpoints as _useBreakpoints } from '@vueuse/core'
import _breakpoints from '@/constants/breakpoints.json'

const breakpoints = _useBreakpoints(_breakpoints)

const isMobile = breakpoints.smaller('sm')
const isTablet = breakpoints.between('sm', 'lg')
const isDesktop = breakpoints.between('lg', 'xl')
const isLargeDesktop = breakpoints.greaterOrEqual('xl')

export function useBreakpoints() {
  return {
    isMobile: readonly(isMobile),
    isTablet: readonly(isTablet),
    isDesktop: readonly(isDesktop),
    isLargeDesktop: readonly(isLargeDesktop),
  }
}