<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'

import QrScanner from 'qr-scanner'
import { Btn } from '@keyo/ui'
import { useModal } from '@/composables'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const MSG_INVALID_QR = t('modules.personal.modals.qrScanner.invalidQrMessage')
const MSG_DISPLAY_DURATION = 5000
// barcode polyfill works much better comparing to jsQR fallback used by qr-scanner
async function initBarcodePolyfill() {
  try {
    ;(window as any).BarcodeDetector.getSupportedFormats()
  } catch {
    console.log('BarcodeDetector not supported, using polyfill')
    const { BarcodeDetectorPolyfill } = await import('@undecaf/barcode-detector-polyfill')
    ;(window as any).BarcodeDetector = BarcodeDetectorPolyfill
  }
}

const router = useRouter()

let qrScanner: QrScanner
const modal = useModal()
const video = ref<HTMLVideoElement>()
const showMsg = ref(false)
const isVideoReady = ref(false)

let msgTimeoutId: ReturnType<typeof setTimeout>
const clearMsgTimeout = () => msgTimeoutId && clearTimeout(msgTimeoutId)

function validateConfirmationURL(str: string) {
  try {
    const url = new URL(str)
    if (
      url.origin === location.origin &&
      url.pathname === router.resolve({ name: 'personal.biometric.confirm-enroll' }).path &&
      url.searchParams.has('orgid') &&
      url.searchParams.has('deviceid')
    ) {
      return url
    }
    return
  } catch (e) {
    return
  }
}

function handleNoAccessToCamera() {
  modal.show('no-access-to-camera', {
    isCustomStyle: true,
  })
}

function handleScan(resultData: string) {
  const url = validateConfirmationURL(resultData)
  if (!url) {
    showMsg.value = true
    clearMsgTimeout()
    msgTimeoutId = setTimeout(() => {
      showMsg.value = false
    }, MSG_DISPLAY_DURATION)

    return
  }

  qrScanner?.stop()
  modal.show('account-biometric-confirm-enroll', {
    isCustomStyle: true,
    organizationId: url.searchParams.get('orgid'),
    deviceId: url.searchParams.get('deviceid'),
  })
}

onBeforeUnmount(() => {
  clearMsgTimeout()
  qrScanner?.destroy()
})

onMounted(async () => {
  try {
    await initBarcodePolyfill()
    const hasCamera = await QrScanner.hasCamera()

    if (!hasCamera) {
      handleNoAccessToCamera()
      return
    }

    if (!video.value) return // for TS and just in case as video should be always available in onMounted hook

    video.value.onloadedmetadata = () => {
      isVideoReady.value = true
    }

    qrScanner = new QrScanner(video.value, result => handleScan(result.data), {
      highlightScanRegion: true,
    })
    await qrScanner.start()
  } catch (error) {
    console.error(error)
    handleNoAccessToCamera()
  }
})

onBeforeUnmount(() => {
  qrScanner?.destroy()
})
</script>

<template>
  <div class="qr-modal">
    <header>
      <Btn icon="left-3" class="btn-header btn--invert" kind="minimal" @click="modal.hide" />
      <h2 class="text-label-l heading">{{ $t('common.scanQRCode') }}</h2>
    </header>
    <div class="video-container">
      <video ref="video" />
      <div v-if="showMsg" class="message">
        {{ MSG_INVALID_QR }}
      </div>
    </div>
    <Btn v-if="isVideoReady" class="btn-footer btn--invert" @click="modal.hide">
      {{ $t('buttons.cancel') }}
    </Btn>
  </div>
</template>

<style scoped lang="scss">
.qr-modal {
  display: flex;
  flex-direction: column;
  max-height: unset !important; // reset default `max-height` modal
  height: 100%;
  width: 100%;
  padding: 0 1.25rem 1.25rem;
  background-color: var(--color-primary-black);
}
header {
  display: flex;
  height: 4rem;
  align-items: center;
  position: relative;
  margin: 0 -1rem 1rem;
}
.heading {
  position: absolute;
  text-align: center;
  inset: auto 4rem;
  color: var(--color-grey-100);
}
.video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}
video {
  display: block;
  width: 100%;
  border-radius: 1.25rem;
}
.message {
  position: absolute;
  bottom: 0;
  background-color: var(--color-grey-800);
  color: var(--color-primary-white);
  font: var(--text-body-s);
  padding: 0.75rem 1rem;
  margin: 0.5rem;
  border-radius: 0.75rem;
  z-index: var(--z-modal);
  transition: opacity 0.3s ease-in-out;
}
// TODO: FE-77 add invert colors handling to the Btn component
.btn--invert {
  &:focus {
    box-shadow: none;
    &:before {
      inset: 0;
      border: 1px solid var(--color-grey-200);
      box-shadow: 0 0 0 1px var(--color-grey-200);
    }
    &:not(:active) {
      box-shadow: none;
    }
  }
}

.btn-footer.btn--invert {
  color: var(--color-primary-black);
  &:before {
    background: var(--color-primary-white);
  }
  &:hover:not(:disabled)::before {
    background: var(--color-grey-100);
  }
  &:focus {
    &:before {
      background-color: var(--color-grey-100);
    }
  }
  &:hover:focus {
    &:before {
      background-color: var(--color-grey-100);
    }
  }
}

.btn-header.btn--invert {
  color: var(--color-primary-white);
  &:active::before {
    background: transparent;
  }
  &:hover:not(:disabled)::before {
    background: transparent;
  }
}
.btn-header.btn--invert:active:not(:disabled)::before {
  background: transparent;
}
</style>
