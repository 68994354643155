<script setup lang="ts">
import ModalCardSuccess from '@/components/modals/components/ModalCardSuccess.vue'
import type { SignInMethod } from '@/api/auth.ts'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{
  method: SignInMethod
}>()

const { t } = useI18n()
const currentMethod = computed(() =>
  t(`modules.auth.modals.activationLinkSuccess.methods.${props.method}`),
)
</script>

<template>
  <ModalCardSuccess
    icon="email-ground"
    icon-color="lavender"
    :heading="$t('modules.auth.modals.activationLinkSuccess.heading', { method: currentMethod })"
  >
    <p class="text-body-m">
      {{ $t('modules.auth.modals.activationLinkSuccess.description', { method: currentMethod }) }}
    </p>
  </ModalCardSuccess>
</template>
